<template>
  <div class="wrapper">
    <CustomList ref="CustomList" :columns="tableColumns" :list-query="listQuery" @getList="getList" />
    <Form ref="Form" @submit="handleSearch()" />
  </div>
</template>

<script>
import CustomList from '@/components/CustomList/index'
import { default as CustomTemplate } from '@/components/CustomList/CustomTemplate'
import { mapActions } from 'vuex'
export default {
  name: 'ExpensesRecord',
  components: { CustomList, ...CustomTemplate },
  props: {
    idNumber: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      listQuery: {
        param: {
          idNumber: this.idNumber
        }
      },
      tableColumns: [
        {
          prop: 'stall',
          title: '档口'
        },
        {
          prop: 'orderNo',
          title: '订单编号'
        },
        {
          prop: 'amount',
          title: '消费金额'
        },
        {
          prop: 'times',
          title: '消费次数'
        },
        {
          prop: 'createTime',
          title: '消费时间'
        }
      ]
    }
  },
  methods: {
    ...mapActions(['mealConsumptionRecordPageList']),
    handleSearch(resetCurrentPage = false, resetSearch = false) {
      if (resetSearch) {
        Object.assign(this.listQuery, this.$options.data.call(this).listQuery)
      }
      this.customListGetList(resetCurrentPage)
    },
    customListGetList(resetCurrentPage = false) {
      this.$refs.CustomList.getList(resetCurrentPage)
    },
    getList(params, callback) {
      this.mealConsumptionRecordPageList(params).then(response => {
        callback(response)
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper{
    overflow: auto;
  }
</style>

<template>
  <div class="wrapper person-manage-add">
    <h4>{{ titleName }}</h4>
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="first">
        <el-form ref="form" v-loading="formLoading" :model="form" :rules="rules" label-width="100px">
          <el-row>
            <el-col :span="12">
              <el-row>
                <el-col :span="24">
                  <el-form-item label="身份证号" prop="idNum">
                    <el-input
                        v-model="form.idNum"
                        :placeholder="routeType == 'detail' ? '' : '请输身份证号'"
                        auto-complete="off"
                        :disabled="routeType == 'detail'"
                        @blur="onIdNumBlur"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="姓名" prop="name">
                    <el-input
                        v-model="form.name"
                        :placeholder="routeType == 'detail' ? '' : '请输入姓名'"
                        auto-complete="off"
                        :disabled="routeType == 'detail'"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="性别">
                    <el-select
                        v-model="form.sex"
                        style="width: 100%"
                        clearable
                        :placeholder="routeType == 'detail' ? '' : '请选择性别'"
                        :disabled="routeType == 'detail'"
                    >
                      <el-option label="男" value="男"/>
                      <el-option label="女" value="女"/>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 出生日期、民族 -->
              <el-row>
                <el-col :span="12">
                  <el-form-item label="年龄">
                    <el-input
                        v-model="form.age"
                        :placeholder="routeType == 'detail' ? '' : '请输入年龄'"
                        auto-complete="off"
                        :disabled="routeType == 'detail'"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="出生日期">
                    <el-date-picker
                        v-model="form.birthDay"
                        :disabled="routeType == 'detail'"
                        style="width: 100%"
                        :default-value="'1940-06-01'"
                        value-format="yyyy-MM-dd"
                        type="date"
                        :placeholder="routeType == 'detail' ? '' : '请选择出生年月日'"
                        auto-complete="off"
                        @blur="onBirthdayBlur"
                    />
                  </el-form-item>
                </el-col>

              </el-row>
              <!-- 城乡类别 -->
              <el-row>
                <el-col :span="12">
                  <el-form-item label="民族">
                    <el-select
                        v-model="form.nation"
                        style="width: 100%"
                        clearable
                        :placeholder="routeType == 'detail' ? '' : '请选择民族'"
                        :disabled="routeType == 'detail'"
                    >
                      <el-option
                          v-for="item in nationalityList"
                          :key="item.dictPidVal"
                          :label="item.dictDesc"
                          :value="item.dictPidVal"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="城乡类别">
                    <el-select
                        v-model="form.cityType"
                        style="width: 100%"
                        clearable
                        :placeholder="routeType == 'detail' ? '' : '请选择城乡类别'"
                        :disabled="routeType == 'detail'"
                    >
                      <el-option
                          v-for="item in cityCategoryList"
                          :key="item.dictPidVal"
                          :label="item.dictDesc"
                          :value="item.dictPidVal"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 婚姻状况、人员级别 -->
              <el-row v-if="routeType != 'detail'">
                <el-col :span="12">
                  <el-form-item label="婚姻状况">
                    <el-select
                        v-model="form.marriage"
                        style="width: 100%"
                        clearable
                        :placeholder="routeType == 'detail' ? '' : '请选择婚姻状况'"
                        :disabled="routeType == 'detail'"
                    >
                      <el-option
                          v-for="item in maritalStatusList"
                          :key="item.dictPidVal"
                          :label="item.dictDesc"
                          :value="item.dictPidVal"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="人员级别">
                    <el-select
                        v-model="personLevelArr"
                        clearable
                        style="width: 100%"
                        :placeholder="routeType == 'detail' ? '' : '请选择人员级别'"
                        :disabled="routeType == 'detail'"
                        multiple
                        @change="selectChange"
                    >
                      <el-option
                          v-for="item in personLevelList"
                          :key="item.dictPidVal"
                          :label="item.dictDesc"
                          :value="item.dictPidVal"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="6">
              <el-form-item v-if="routeType != 'detail' && !photoUrl" label=" ">
                <upload-img style="width:100px;height:100px;" @uploadChange="uploadSuccess"/>
                <p style="margin-left: 24px;">上传照片</p>
              </el-form-item>
              <div v-if="form.photo && photoUrl" class="picture">
                <img :src="photoUrl" alt="">
              </div>
              <el-form-item v-if="routeType == 'detail' && !form.photo" label=" ">
                <img v-if="form.sex == '男'" src="@/assets/images/man.jpeg" alt="">
                <img v-if="form.sex == '女'" src="@/assets/images/women.jpeg" alt="">
              </el-form-item>
              <el-form-item v-if="routeType != 'detail'" label=" " style="margin-top:-20px;margin-bottom:10px;">
                <el-button @click="creatQrCode">生成二维码</el-button>
              </el-form-item>
              <div ref="qrCodeUrl" class="qrcode"/>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="联系方式" prop="contract">
                <el-input
                    v-model="form.contract"
                    :placeholder="routeType == 'detail' ? '' : '请输入联系方式'"
                    auto-complete="off"
                    :disabled="routeType == 'detail'"
                    style="width:60%"
                />
                <el-select
                    v-model="form.contractStatus"
                    style="width: 40%"
                    clearable
                    placeholder="状态"
                    :disabled="routeType == 'detail'"
                >
                  <el-option
                      v-for="item in contractStatusList"
                      :key="item.dictPidVal"
                      :label="item.dictDesc"
                      :value="item.dictPidVal"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-if="form.personLevel.indexOf('103_5') != -1" :span="12">
              <el-form-item label="机构名称" class="c-red">
                <el-select
                    v-model="form.inPensOrgId"
                    style="width: 100%"
                    filterable
                    clearable
                    :placeholder="routeType == 'detail' ? '' : '请选择入住养老机构名称'"
                    :disabled="routeType == 'detail'"
                >
                  <el-option
                      v-for="item in personOrgList"
                      :key="item.orgId"
                      :label="item.orgName"
                      :value="item.orgId"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-if="form.personLevel.indexOf('103_5') != -1" :span="6">
              <el-form-item label="入住时间" class="c-red">
                <el-date-picker
                    v-model="form.inPensOrgBeginTime"
                    :disabled="routeType == 'detail'"
                    style="width: 160px"
                    value-format="yyyy-MM-dd"
                    type="date"
                    :placeholder="routeType == 'detail' ? '' : '请选择入住时间'"
                    auto-complete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 当前状态 -->
          <el-row>
            <el-col :span="6">
              <el-form-item label="当前状态">
                <el-select
                    v-model="form.currentStatus"
                    style="width: 100%"
                    clearable
                    :placeholder="routeType == 'detail' ? '' : '当前状态'"
                    :disabled="routeType == 'detail'"
                >
                  <el-option
                      v-for="item in currentStatusList"
                      :key="item.dictPidVal"
                      :label="item.dictDesc"
                      :value="item.dictPidVal"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-if="form.personLevel.indexOf('103_5') != -1" :span="6">
              <el-form-item label="护理等级" class="c-red">
                <el-select
                    v-model="form.nursingLevel"
                    style="width: 100%"
                    clearable
                    :placeholder="routeType == 'detail' ? '' : '请选择护理等级'"
                    :disabled="routeType == 'detail'"
                >
                  <el-option
                      v-for="item in nursingLevelList"
                      :key="item.dictPidVal"
                      :label="item.dictDesc"
                      :value="item.dictPidVal"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-if="form.personLevel.indexOf('103_5') != -1" :span="6">
              <el-form-item label="是否离院" class="c-red">
                <el-radio-group v-model="form.inPensOrgIsLeave" :disabled="routeType == 'detail'">
                  <el-radio :label="'1'">是</el-radio>
                  <el-radio :label="'0'">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col v-if="form.personLevel == '103_5' && form.inPensOrgIsLeave == 1" :span="6">
              <el-form-item label="离院时间" class="c-red">
                <el-date-picker
                    v-model="form.inPensOrgEndTime"
                    :disabled="routeType == 'detail'"
                    style="width: 160px"
                    value-format="yyyy-MM-dd"
                    type="date"
                    :placeholder="routeType == 'detail' ? '' : '请选择离院时间'"
                    auto-complete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 积分、默认账户 -->
          <el-row>
            <el-col :span="6">
              <el-form-item label="积分">
                <el-input
                    v-model="form.integral"
                    :placeholder="routeType == 'detail' ? '' : '请输入'"
                    auto-complete="off"
                    :disabled="routeType == 'detail'"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="默认账户">
                <el-input
                    v-model="form.defaultAccount"
                    :placeholder="routeType == 'detail' ? '' : '请输入默认账户'"
                    auto-complete="off"
                    :disabled="routeType == 'detail'"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="卡号">
                <el-input
                    v-model="form.cardNum"
                    auto-complete="off"
                    :placeholder="routeType == 'detail' ? '' : '请输入卡号'"
                    :disabled="routeType == 'detail'"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 户籍地址 -->
          <el-row :gutter="6">
            <el-col :span="12">
              <el-form-item label="户籍地址" prop="censusTenant">
                <region-form ref="censusRegionForm" v-model="form.censusTenant" :disabled="regionDisabled"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="户籍详细地址">
                <el-input
                    v-model="form.censusAddress"
                    :placeholder="routeType == 'detail' ? '' : '请输入详细地址'"
                    auto-complete="off"
                    :disabled="routeType == 'detail'"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 常住地址 -->
          <el-row :gutter="6">
            <el-col :span="12">
              <el-form-item label="常住地址" prop="tenant">
                <region-form ref="regionForm" v-model="form.tenant" :disabled="regionDisabled"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="常住详细地址">
                <el-input
                    v-model="form.usualAddress"
                    :placeholder="routeType == 'detail' ? '' : '请输入常住地址'"
                    auto-complete="off"
                    :disabled="routeType == 'detail'"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="经度" prop="longitude">
                <el-input
                    v-model="form.longitude"
                    :placeholder="routeType == 'detail' ? '' : '请输入经度'"
                    auto-complete="off"
                    :disabled="routeType == 'detail'"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="纬度" prop="latitude">
                <el-input
                    v-model="form.latitude"
                    :placeholder="routeType == 'detail' ? '' : '请输入纬度'"
                    auto-complete="off"
                    :disabled="routeType == 'detail'"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="家庭人数" prop="familyCount">
                <el-input
                    v-model="form.familyCount"
                    :placeholder="routeType == 'detail' ? '' : '请输入家庭人数'"
                    auto-complete="off"
                    :disabled="routeType == 'detail'"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-collapse accordion @change="changeCollapse">
            <el-collapse-item>
              <template slot="title">
                <div class="add-title1">
                  <i class="el-icon-s-custom margin-right-10"/>
                  紧急联系人
                  <el-button
                      v-if="routeType != 'detail' && isShowAdd"
                      class="el-button margin-left-10"
                      size="medium"
                      @click.stop="addContract"
                  ><i class="vxe-button--icon fa fa-plus"/></el-button>
                </div>
              </template>
              <el-row v-for="(item, index) in form.contractList" :key="index" :gutter="20">
                <el-row :gutter="20">
                  <!-- 姓名 -->
                  <el-col :span="8">
                    <el-form-item label="联系人姓名">
                      <el-input
                          v-model="item.contractName"
                          :placeholder="routeType == 'detail' ? '' : '请输入联系人姓名'"
                          auto-complete="off"
                          :disabled="routeType == 'detail'"
                      />
                    </el-form-item>
                  </el-col>
                  <!-- 联系方式 -->
                  <el-col :span="8">
                    <el-form-item label="联系人电话" prop="contractTel">
                      <el-input
                          v-model="item.contractTel"
                          :placeholder="routeType == 'detail' ? '' : '请输入联系人的联系方式'"
                          auto-complete="off"
                          :disabled="routeType == 'detail'"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="1">
                    <button
                        v-if="routeType != 'detail'"
                        class="el-button el-button--danger"
                        @click.stop="delContract(index)"
                    >删除
                    </button>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <!-- 联系人与本人关系 -->
                  <el-col :span="8">
                    <el-form-item label="与本人关系">
                      <el-input
                          v-model="item.contractRelateShip"
                          :placeholder="routeType == 'detail' ? '' : '请输入联系人与本人关系'"
                          auto-complete="off"
                          :disabled="routeType == 'detail'"
                      />
                    </el-form-item>
                  </el-col>
                  <!-- 联系人其他信息 -->
                  <el-col :span="8">
                    <el-form-item label="备注">
                      <el-input
                          v-model="item.contractOthers"
                          :placeholder="routeType == 'detail' ? '' : '请输入联系人其他信息'"
                          auto-complete="off"
                          :disabled="routeType == 'detail'"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <!-- 联系人住址 -->
                  <el-col :span="16">
                    <el-form-item label="联系人住址">
                      <el-input
                          v-model="item.contractAddress"
                          :placeholder="routeType == 'detail' ? '' : '请输入联系人住址'"
                          auto-complete="off"
                          :disabled="routeType == 'detail'"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-row>
            </el-collapse-item>
          </el-collapse>
          <div class="add-title expand">
            <i class="el-icon-s-cooperation"/>
            扩展信息
          </div>
          <!-- 政治面貌 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="政治面貌">
                <el-radio-group v-model="form.politicalStatus" :disabled="routeType == 'detail'">
                  <el-radio v-for="item in poliStatusList" :key="item.dictPidVal" :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 人员类别 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="人员类别:">
                <el-checkbox-group v-model="form.personType" :disabled="routeType == 'detail'">
                  <el-checkbox v-for="item in personCategoryList" :key="item.dictPidVal" :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 项目服务 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="项目服务:">
                <el-checkbox-group v-model="form.projectService" :disabled="routeType == 'detail'"
                                   @change="changeProjectService">
                  <el-checkbox v-for="item in projectServiceList" :key="item.dictPidVal" :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 政府项目服务类别 -->
          <el-row v-if="form.projectService.includes('163_101')">
            <el-col :span="24">
              <el-form-item label="政府项目服务类别:">
                <el-radio-group v-model="form.zfProjectServiceCategory" :disabled="routeType == 'detail'">
                  <el-radio v-for="item in zfProjectServiceCategoryList" :key="item.dictPidVal"
                            :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 慢性病 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="慢性病:">
                <el-checkbox-group v-model="form.chronic" :disabled="routeType == 'detail'">
                  <el-checkbox v-for="item in chronicList" :key="item.dictPidVal" :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 居住状况 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="居住情况:">
                <el-radio-group v-model="form.living" :disabled="routeType == 'detail'">
                  <el-radio v-for="item in livingList" :key="item.dictPidVal" :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 子女状况 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="子女状况:">
                <el-radio-group v-model="form.children" :disabled="routeType == 'detail'">
                  <el-radio v-for="item in childrenList" :key="item.dictPidVal" :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 生活自理能力 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="生活自理能力:">
                <el-radio-group v-model="form.selfCareAbility" :disabled="routeType == 'detail'">
                  <el-radio v-for="item in selfCareAbiyList" :key="item.dictPidVal" :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 补贴 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="是否领取高龄补贴">
                <el-radio-group v-model="form.oldAgeAllowance" :disabled="routeType == 'detail'">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 是否参加老年大学 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="是否参加老年大学">
                <el-radio-group v-model="form.elderUniversity" :disabled="routeType == 'detail'">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 是否参加老年协会 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="是否参加老年协会">
                <el-radio-group v-model="form.seniorCityAssoc" :disabled="routeType == 'detail'">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 社保卡号 -->
          <el-row>
            <el-col :span="16">
              <el-form-item label="社保卡号:">
                <el-input
                    v-model="form.socialSecurityCardNum"
                    :placeholder="routeType == 'detail' ? '' : '请输入社保卡号'"
                    auto-complete="off"
                    :disabled="routeType == 'detail'"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 能力评估结果 -->
          <el-row>
            <el-col :span="24">
              <!-- <el-form-item label="能力评估结果:">
                <el-checkbox-group v-model="form.capabilityAssessResult" :disabled="routeType == 'detail'">
                  <el-checkbox
                    v-for = "item in  abiEvalRstList"
                    :key = "item.dictPidVal"
                    :label= "item.dictPidVal">
                    {{item.dictDesc}}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item> -->
              <el-form-item label="常用药物:">
                <el-input
                    v-model="form.commonlyUsedDrug"
                    :placeholder="routeType == 'detail' ? '' : '请输入常用药物'"
                    auto-complete="off"
                    :disabled="routeType == 'detail'"
                />
              </el-form-item>

            </el-col>
          </el-row>
          <!-- 退休前职业 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="退休前职业:">
                <el-radio-group v-model="form.beforeJob" :disabled="routeType == 'detail'">
                  <el-radio v-for="item in preJobList" :key="item.dictPidVal" :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 经济来源 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="经济来源:">
                <el-checkbox-group v-model="form.incomeSource" :disabled="routeType == 'detail'">
                  <el-checkbox v-for="item in incomeSourceList" :key="item.dictPidVal" :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 住房性质 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="住房性质:">
                <el-checkbox-group v-model="form.housNature" :disabled="routeType == 'detail'">
                  <el-checkbox v-for="item in housNatureList" :key="item.dictPidVal" :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 住宅类型 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="住宅类型:">
                <el-radio-group v-model="form.houseType" :disabled="routeType == 'detail'">
                  <el-radio v-for="item in houseTypeList" :key="item.dictPidVal" :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 就医方式 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="就医方式:">
                <el-radio-group v-model="form.treatMethod" :disabled="routeType == 'detail'">
                  <el-radio v-for="item in doctTypeList" :key="item.dictPidVal" :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 文化程度 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="文化程度:">
                <el-radio-group v-model="form.education" :disabled="routeType == 'detail'">
                  <el-radio v-for="item in educatList" :key="item.dictPidVal" :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 参保情况 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="参保情况:">
                <el-checkbox-group v-model="form.insurance" :disabled="routeType == 'detail'">
                  <el-checkbox v-for="item in insureList" :key="item.dictPidVal" :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 是否残疾 -->
          <el-row>
            <el-col :span="8">
              <el-form-item label="是否残疾:">
                <el-radio-group v-model="form.disability" :disabled="routeType == 'detail'">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 听力残疾-->
          <el-row>
            <el-col :span="24">
              <el-form-item v-if="form.disability == 1" label="听力残疾:">
                <el-radio-group v-model="form.hear" :disabled="routeType == 'detail'">
                  <el-radio
                      v-for="item in disabilityList"
                      :key="item.dictPidVal"
                      :label="item.dictPidVal"
                      @click.native.prevent="hearClick(item.dictPidVal)"
                  >
                    {{ item.dictDesc }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item v-if="form.disability == 1" label="视力残疾:">
                <el-radio-group v-model="form.vision" :disabled="routeType == 'detail'">
                  <el-radio
                      v-for="item in disabilityList"
                      :key="item.dictPidVal"
                      :label="item.dictPidVal"
                      @click.native.prevent="visionClick(item.dictPidVal)"
                  >
                    {{ item.dictDesc }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item v-if="form.disability == 1" label="智力残疾:">
                <el-radio-group v-model="form.intelligence" :disabled="routeType == 'detail'">
                  <el-radio
                      v-for="item in disabilityList"
                      :key="item.dictPidVal"
                      :label="item.dictPidVal"
                      @click.native.prevent="intelligenceClick(item.dictPidVal)"
                  >
                    {{ item.dictDesc }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item v-if="form.disability == 1" label="肢体残疾:">
                <el-radio-group v-model="form.limb" :disabled="routeType == 'detail'">
                  <el-radio
                      v-for="item in disabilityList"
                      :key="item.dictPidVal"
                      :label="item.dictPidVal"
                      @click.native.prevent="limbClick(item.dictPidVal)"
                  >
                    {{ item.dictDesc }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item v-if="form.disability == 1" label="语言残疾:">
                <el-radio-group v-model="form.speech" :disabled="routeType == 'detail'">
                  <el-radio
                      v-for="item in disabilityList"
                      :key="item.dictPidVal"
                      :label="item.dictPidVal"
                      @click.native.prevent="speechClick(item.dictPidVal)"
                  >
                    {{ item.dictDesc }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item v-if="form.disability == 1" label="精神残疾:">
                <el-radio-group v-model="form.spirit" :disabled="routeType == 'detail'">
                  <el-radio
                      v-for="item in disabilityList"
                      :key="item.dictPidVal"
                      :label="item.dictPidVal"
                      @click.native.prevent="spiritClick(item.dictPidVal)"
                  >
                    {{ item.dictDesc }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 养老方式 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="养老方式:">

                <el-radio-group v-model="form.pensionerType" :disabled="routeType == 'detail'">
                  <el-radio v-for="item in permsionMethodList" :key="item.dictPidVal" :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 助洁 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="助洁:">
                <el-checkbox-group v-model="form.helpClean" :disabled="routeType == 'detail'">
                  <el-checkbox v-for="item in helpCleanList" :key="item.dictPidVal" :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="4">
              <el-form-item label="需求频次：">
                <el-input size="mini" placeholder="请输入次数"></el-input>
              </el-form-item>
            </el-col> -->
          </el-row>
          <!-- 助浴 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="助浴:">
                <el-checkbox-group v-model="form.helpBath" :disabled="routeType == 'detail'">
                  <el-checkbox v-for="item in helpBathList" :key="item.dictPidVal" :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="4">
              <el-form-item label="需求频次：">
                <el-input size="mini" placeholder="请输入次数"></el-input>
              </el-form-item>
            </el-col> -->
          </el-row>
          <!-- 助行 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="助行:">
                <el-checkbox-group v-model="form.helpWork" :disabled="routeType == 'detail'">
                  <el-checkbox v-for="item in helpWorkList" :key="item.dictPidVal" :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="4">
             <el-form-item label="需求频次：">
               <el-input size="mini" placeholder="请输入次数"></el-input>
             </el-form-item>
           </el-col> -->
          </el-row>
          <!-- 助医 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="助医:">
                <el-checkbox-group v-model="form.helpDoctor" :disabled="routeType == 'detail'">
                  <el-checkbox v-for="item in helpDoctorList" :key="item.dictPidVal" :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="4">
             <el-form-item label="需求频次：">
               <el-input size="mini" placeholder="请输入次数"></el-input>
             </el-form-item>
           </el-col> -->
          </el-row>
          <!-- 助急 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="助急:">
                <el-checkbox-group v-model="form.helpRescue" :disabled="routeType == 'detail'">
                  <el-checkbox v-for="item in helpRescueList" :key="item.dictPidVal" :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="4">
             <el-form-item label="需求频次：">
               <el-input size="mini" placeholder="请输入次数"></el-input>
             </el-form-item>
           </el-col> -->
          </el-row>
          <!-- 精神文化娱乐 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="精神文化娱乐:">
                <el-checkbox-group v-model="form.entertainment" :disabled="routeType == 'detail'">
                  <el-checkbox v-for="item in entertainList" :key="item.dictPidVal" :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 心理慰籍 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="心理慰籍:">
                <el-checkbox-group v-model="form.psychologicalComfort" :disabled="routeType == 'detail'">
                  <el-checkbox v-for="item in comfortList" :key="item.dictPidVal" :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 养老服务需求评估 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="养老需求评估:">
                <el-checkbox-group v-model="form.pensionNnedAssess" :disabled="routeType == 'detail'">
                  <el-checkbox v-for="item in serviceEvalList" :key="item.dictPidVal" :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 综合服务 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="综合服务:">
                <el-checkbox-group v-model="form.generalService" :disabled="routeType == 'detail'">
                  <el-checkbox v-for="item in genServiceList" :key="item.dictPidVal" :label="item.dictPidVal">
                    {{ item.dictDesc }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane v-if="routeType == 'detail'" label="老年人评估" name="oldChangeDetail">
        <oldChangeDetail v-permission="'AddPersonOldChangeTab'"/>
      </el-tab-pane>
      <el-tab-pane v-if="routeType == 'detail'" label="适老化改造" name="oldChangeTab">
        <oldChange v-if="isoldChangeTab" v-permission="'AddPersonOldChangeTab'"/>
      </el-tab-pane>
      <el-tab-pane v-if="routeType == 'detail'" label="服务记录" name="three">
        <serviceRecord v-if="isrecord" v-permission="'AddPersonThreeTab'"
                       :service-old-person-id-prop="serviceOldPersonId" :has-tenant="false"/>
      </el-tab-pane>
      <el-tab-pane v-if="routeType == 'detail'" label="体检报告" name="examinationReport">
        <examination-report v-permission="'AddPersonExaminationReportTab'" :person-id="$route.params.id"/>
      </el-tab-pane>
      <el-tab-pane v-if="routeType == 'detail'" label="充值记录" name="second">
        <finance-record v-permission="'AddPersonSecondTab'" :person-id="$route.params.id"/>
      </el-tab-pane>
      <el-tab-pane v-if="routeType == 'detail'" label="健康管理报告" name="health">
        <health-report v-permission="'AddPersonHealthTab'"/>
      </el-tab-pane>
      <el-tab-pane v-if="routeType == 'detail'" label="健康评估" name="assess">
        <a v-permission="'AddPersonAssessTab'" class="link-a" href="/老年人能力评估基本信息表.doc" download="老年人能力评估基本信息表.doc">老年人能力评估基本信息表.doc</a>
      </el-tab-pane>
      <el-tab-pane v-if="routeType == 'detail'" label="时间银行" name="timeBank">
        <time-bank v-permission="'AddPersonAimeBankTab'" :person-id="$route.params.id"/>
      </el-tab-pane>
      <el-tab-pane v-if="routeType == 'detail'" label="活动记录" name="activeRecord">
        <activity-record v-if="isActiveRecord" v-permission="'AddPersonActiveRecordTab'"/>
      </el-tab-pane>
      <el-tab-pane v-if="routeType == 'detail'" label="智能设备" name="intelligentDevice">
        <intelligent-device v-permission="'AddPersonIntelligentDeviceTab'" :form="form"/>
      </el-tab-pane>
      <el-tab-pane v-if="routeType == 'detail'" label="消费记录" name="expensesRecord">
        <expenses-record v-if="form && form.idNum" v-permission="'AddPersonExpensesRecordTab'" :id-number="form.idNum"/>
      </el-tab-pane>
    </el-tabs>

    <div class="m-footer">
      <el-button v-if="routeType != 'detail'" type="primary" icon="fa fa-check" @click="submitEvent('form')">保存
      </el-button>
      <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
      <el-button v-permission="'AddPersonServerRecodeButton'" icon=""
                 @click="() => { $router.push({ name: 'serverRecodeTxt' }) }">养老顾问服务报告
      </el-button>

    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UploadImg from '@/components/UploadImg.vue'
import HealthReport from './HealthReport/index.vue'
import TimeBank from './timeBank/index.vue'
import intelligentDevice from './intelligentDevice/index.vue'
import QRCode from 'qrcodejs2'
import FinanceRecord from './financeRecord.vue'
import serviceRecord from '../../ServeRecords/list.vue'
import activityRecord from '../../ServeOrgs/components/activityRecord.vue'
import oldChange from './oldChange.vue'
import oldChangeDetail from './oldChangeDetail.vue'
import examinationReport from './examinationReport'
import RegionForm from '@/components/RegionForm.vue'
import ExpensesRecord from './expensesRecord/index'

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$route.params.tab != 1) {
        if (from.name == 'AddActivityRecord') {
          vm.activeName = 'activeRecord'
          vm.isActiveRecord = true
        }
      }
    })
  },
  name: 'AddOrEdit',
  components: {
    ExpensesRecord,
    UploadImg,
    HealthReport,
    FinanceRecord,
    TimeBank,
    serviceRecord,
    activityRecord,
    oldChange,
    oldChangeDetail,
    intelligentDevice,
    examinationReport,
    RegionForm
  },
  data() {
    var validIdNum = (rule, value, callback) => {
      if (value && !this.$regex.idCardRegEx(this.form.idNum)) {
        callback(new Error('身份证号格式不正确'))
      } else {
        callback()
      }
    }
    var validContract = (rule, value, callback) => {
      if (value && !this.$regex.telRegEx(this.form.contract)) {
        callback(new Error('联系方式格式不正确'))
      } else {
        callback()
      }
    }
    var validContractTel = (rule, value, callback) => {
      if (value && !this.$regex.telRegEx(this.form.contractTel)) {
        callback(new Error('联系人的联系方式格式不正确'))
      } else {
        callback()
      }
    }
    return {
      serviceOldPersonId: this.$route.params.id,
      titleName: '',
      activeName: 'first',
      activeNames: ['1'],
      options: [],
      hasIdNum: false,
      photoUrl: '',
      formLoading: false,
      isrecord: false,
      regionDisabled: false,
      form: {
        cardNum: '',
        bedDeviceId: '',
        idNum: '',
        age: '',
        birthDay: '',
        disability: '',
        hear: '',
        vision: '',
        limb: '',
        intelligence: '',
        speech: '',
        spirit: '',
        personType: [],
        chronic: [],
        capabilityAssessResult: [],
        commonlyUsedDrug: '',
        incomeSource: [],
        housNature: [],
        houseType: '',
        insurance: [],
        helpClean: [],
        helpBath: [],
        helpWork: [],
        helpDoctor: [],
        helpRescue: [],
        entertainment: [],
        psychologicalComfort: [],
        pensionNnedAssess: [],
        generalService: [],
        longitude: '',
        latitude: '',
        familyCount: '',
        contractStatus: '',
        projectService: [],
        zfProjectServiceCategory: '',
        personLevel: '',
        contractList: []
      },
      personLevelArr: [],
      tablePage: {
        totalResult: 0,
        currentPage: 0,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 200, 500, 1000],
        perfect: true
      },
      plateNoId: '',
      value: '',
      cityCategoryList: [],
      educatList: [],
      preJobList: [], // 退休前职业
      abiEvalRstList: [],
      childrenList: [],
      chronicList: [],
      currentStatusList: [],
      contractStatusList: [], // 手机号状态（字典）
      disabilityList: [],
      entertainList: [],
      genServiceList: [],
      helpCleanList: [],
      helpBathList: [],
      helpDoctorList: [],
      helpRescueList: [],
      helpWorkList: [],
      housNatureList: [],
      incomeSourceList: [],
      insureList: [],
      livingList: [],
      maritalStatusList: [],
      missedReasonList: [],
      serviceEvalList: [],
      permsionMethodList: [],
      personLevelList: [],
      personCategoryList: [],
      poliStatusList: [],
      comfortList: [],
      selfCareAbiyList: [],
      doctTypeList: [],
      nationalityList: [],
      provinceList: [],
      cityList: [],
      areaList: [],
      streetList: [],
      communityList: [],
      provinceList1: [],
      cityList1: [],
      areaList1: [],
      streetList1: [],
      communityList1: [],
      nursingLevelList: [],
      houseTypeList: [],
      projectServiceList: [],
      zfProjectServiceCategoryList: [],
      personOrgList: [], // 养老机构列表
      isActiveRecord: 'false',
      isShowAdd: false,
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        contract: [
          { required: true, message: '请输入联系方式', trigger: 'blur' }
          // { validator: validContract, trigger: 'blur' }
        ],
        censusAddress: [{ required: true, message: '请输入户籍地址', trigger: 'blur' }],
        usualAddress: [{ required: true, message: '请输入常住地址', trigger: 'blur' }],
        idNum: [
          { validator: validIdNum, trigger: 'blur' }
        ],
        contractTel: [
          { validator: validContractTel, trigger: 'blur' }
        ]
      },
      isoldChangeTab: false
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    },
    searchist() {
      var list = []
      var _list = []
      var _this = this
      this.seqList.map(function (item) {
        if (item.plateNo.indexOf(_this.plateNoId) != -1) {
          // 然后把当前json添加到list数组中
          _list.push(item)
          list = _list
        }
      })
      return listinitView
    }
  },
  created() {
    this.getDicList()
    this.getPersonOrgList()
    this.initView()
    // console.log(this.$route.params.tab);
    // if(this.$route.params.tab==1){
    //   console.log('tab===1');
    //   this.activeName = 'first'
    // }
  },
  methods: {
    ...mapActions([
      'downloadFile',
      'addHomeOldPersonServe',
      'getSysDictServe',
      'detailHomeOldPersonServe',
      'getProvincesServe',
      'getCitysServe',
      'getAreasServe',
      'getStreetsServe',
      'getCommunitysServe',
      'getFileDetailServe',
      'getPersonOrgListServe'
    ]),
    changeProjectService() {
      if (!this.form.projectService.includes('163_101')) {
        this.form.zfProjectServiceCategory = ''
      }
    },
    changeCollapse(e) {
      this.isShowAdd = e !== ''
    },
    /**
     * 添加紧急联系人
     */
    addContract() {
      if (this.form.contractList.length >= 5) {
        this.$message.warning('最多添加5条')
        return
      }
      this.form.contractList.push({
        contractTel: '',
        contractName: '',
        contractRelateShip: '',
        contractAddress: '',
        contractOthers: ''
      })
      console.log(this.form.contractList)
    },
    /**
     * 删除紧急联系人
     * @param index
     */
    delContract(index) {
      this.form.contractList.splice(index, 1)
    },
    handleClick(tab, event) {
      if (tab.name == 'three') {
        this.isrecord = true
        this.isActiveRecord = false
        this.isoldChangeTab = false
      } else if (tab.name == 'activeRecord') {
        this.isActiveRecord = true
        this.isoldChangeTab = false
        this.isrecord = false
      } else if (tab.name == 'oldChangeTab') {
        this.isoldChangeTab = true
        this.isActiveRecord = false
        this.isrecord = false
      } else {
        this.isActiveRecord = false
        this.isrecord = false
        this.isoldChangeTab = false
      }
    },
    hearClick(e) {
      e === this.form.hear ? this.form.hear = '' : this.form.hear = e
    },
    visionClick(e) {
      e === this.form.vision ? this.form.vision = '' : this.form.vision = e
    },
    intelligenceClick(e) {
      e === this.form.intelligence ? this.form.intelligence = '' : this.form.intelligence = e
    },
    limbClick(e) {
      e === this.form.limb ? this.form.limb = '' : this.form.limb = e
    },
    speechClick(e) {
      e === this.form.speech ? this.form.speech = '' : this.form.speech = e
    },
    spiritClick(e) {
      e === this.form.spirit ? this.form.spirit = '' : this.form.spirit = e
    },
    onIdNumBlur() {
      if (this.form.idNum.charAt(16) !== '' && this.form.sex !== '男' && this.form.sex !== '女') {
        this.form.sex = this.form.idNum.charAt(16) % 2 === 0 ? '女' : '男'
      }
      if (this.form.idNum && this.$regex.idCardRegEx(this.form.idNum)) {
        this.getBirthday(this.form.idNum)
      } else {
        this.hasIdNum = false
      }
    },
    onBirthdayBlur() {
      this.form.age = this.$tools.getAge(this.form.birthDay)
    },
    getBirthday(idNum) {
      const UUserCard = idNum
      var cc = UUserCard.substring(6, 10) + '-' + UUserCard.substring(10, 12) + '-' + UUserCard.substring(12, 14)
      var myDate = new Date()
      var month = myDate.getMonth() + 1
      var day = myDate.getDate()
      var age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1
      if (UUserCard.substring(10, 12) < month || UUserCard.substring(10, 12) == month && UUserCard.substring(12, 14) <= day) {
        age++
      }
      this.form.birthDay = cc
      this.form.age = age
      this.hasIdNum = true
    },
    // 生成二维码
    creatQrCode() {
      // if (!this.form.name) {
      //   this.$message.warning('请先输入姓名！')
      //   return
      // }
      // if (!this.form.age) {
      //   this.$message.warning('请先输入年龄！')
      //   return
      // }
      // if (!this.form.contract) {
      //   this.$message.warning('请先输入联系方式！')
      //   return
      // }
      // if (!this.form.usualAddress) {
      //   this.$message.warning('请先输入常住住址！')
      //   return
      // }
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: `老人姓名：${this.form.name}\n年龄：${this.form.age}\n联系电话：${this.form.contract}\n住址：${this.form.usualAddress}`, // 需要转换为二维码的内容
        width: 100,
        height: 100,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    focusEvent1() {
      this.$refs.xDown1.showPanel()
    },
    getDicList() {
      this.getSysDictList('cityCategory', 'cityCategoryList') // 城乡类别
      this.getSysDictList('educat', 'educatList') // 文化程度
      this.getSysDictList('preJob', 'preJobList') // 退休前职业
      this.getSysDictList('abiEvalRst', 'abiEvalRstList') // 能力评估结果
      this.getSysDictList('children', 'childrenList') // 子女状况
      this.getSysDictList('chronic', 'chronicList') // 慢性病
      this.getSysDictList('currentStatus', 'currentStatusList') // 当前状态
      this.getSysDictList('missedReason', 'contractStatusList') // 手机号状态状态

      this.getSysDictList('disability', 'disabilityList') // 残疾类别
      this.getSysDictList('entertain', 'entertainList') // 精神文化休闲娱乐
      this.getSysDictList('genService', 'genServiceList') // 综合服务
      this.getSysDictList('helpClean', 'helpCleanList') // 助洁
      this.getSysDictList('helpBath', 'helpBathList') // 助浴
      this.getSysDictList('helpDoct', 'helpDoctorList') // 助医
      this.getSysDictList('helpMerg', 'helpRescueList') // 助急
      this.getSysDictList('helpWork', 'helpWorkList') // 助行
      this.getSysDictList('housNature', 'housNatureList') // 住房性质
      this.getSysDictList('incomeSource', 'incomeSourceList') // 经济来源
      this.getSysDictList('insure', 'insureList') // 参保情况
      this.getSysDictList('living', 'livingList') // 居住情况
      this.getSysDictList('maritalStatus', 'maritalStatusList') // 婚姻情况
      this.getSysDictList('missedReason', 'missedReasonList') // 未接听原因
      this.getSysDictList('serviceEval', 'serviceEvalList') // 养老服务需求评估
      this.getSysDictList('permsionMethod', 'permsionMethodList') // 养老方式
      this.getSysDictList('personLevel', 'personLevelList') // 人员级别
      this.getSysDictList('personCategory', 'personCategoryList') // 人员类别
      this.getSysDictList('poliStatus', 'poliStatusList') // 政治面貌
      this.getSysDictList('comfort', 'comfortList') // 心理慰藉
      this.getSysDictList('selfCareAbiy', 'selfCareAbiyList') // 生活自理能力
      this.getSysDictList('doctType', 'doctTypeList') // 就医方式
      this.getSysDictList('nationality', 'nationalityList') // 民族
      this.getSysDictList('nursingLevel', 'nursingLevelList') // 护理等级
      this.getSysDictList('houseType', 'houseTypeList') // 住宅类型
      this.getSysDictList('projectService', 'projectServiceList') // 项目服务
      this.getSysDictList('zfProjectServiceCategory', 'zfProjectServiceCategoryList') // 政府项目服务类别
    },
    initView() {
      if (this.routeType == 'add') {
        this.titleName = '添加人员'
      } else {
        this.titleName = this.routeType == 'detail' ? '人员详情' : '修改人员'
        // this.itemData = JSON.parse(this.$route.query.item);
        this.getPersonDetail()
      }
      this.regionDisabled = this.routeType == 'detail'
    },
    // 获取入住养老机构列表
    getPersonOrgList() {
      const params = {
        current: 1,
        size: 99,
        param: {
          orgName: ''
        }
      }
      this.getPersonOrgListServe(params).then(res => {
        if (res.code == 200) {
          this.personOrgList = res.data.records
        }
      })
    },
    // 获取数据字典
    getSysDictList(code, codeList) {
      const params = {
        typeCode: code
      }
      this.getSysDictServe(params).then((res) => {
        if (res.code == 200) {
          this[codeList] = res.data
        }
      })
    },
    uploadSuccess(id) {
      this.form.photo = id.join(',')
    },
    submitEvent(formName) {
      if (!this.form.name) {
        this.$alert('请输入姓名！', '提示', {
          confirmButtonText: '确定',
          type: 'warning'
        })
        return
      }
      if (!this.form.contract) {
        this.$alert('请输入联系方式！', '提示', {
          confirmButtonText: '确定',
          type: 'warning'
        })
        return
      }
      if (this.form.idNum && !this.$regex.idCardRegEx(this.form.idNum)) {
        this.$alert('身份证号格式不对！', '提示', {
          confirmButtonText: '确定',
          type: 'warning'
        })
        return
      }
      // if(this.form.contract && !this.$regex.telRegEx(this.form.contract)) {
      //   this.$alert('联系方式格式不对！', '提示', {
      //     confirmButtonText: '确定',
      //     type: 'warning'
      //   });
      //   return;
      // }
      if (this.form.contractTel && !this.$regex.telRegEx(this.form.contractTel)) {
        this.$alert('联系人的联系方式格式不对！', '提示', {
          confirmButtonText: '确定',
          type: 'warning'
        })
        return
      }
      if (!this.form.censusTenant) {
        this.$alert('请选择户籍地址！', '提示', {
          confirmButtonText: '确定',
          type: 'warning'
        })
        return
      }
      if (!this.form.tenant) {
        this.$alert('请选择常住地址！', '提示', {
          confirmButtonText: '确定',
          type: 'warning'
        })
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = { ...this.form }
          params.tenant = `1,101,${this.form.tenant.join(',')}`
          params.censusTenant = `1,101,${this.form.censusTenant.join(',')}`
          if (params.zfProjectServiceCategory !== '') {
            params.zfProjectServiceCategory = [params.zfProjectServiceCategory]
          } else {
            params.zfProjectServiceCategory = []
          }
          this.addHomeOldPersonServe(params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: '更新成功',
                status: 'success'
              })
              this.$router.go(-1)
            }
          })
        } else {
          return false
        }
      })
    },
    selectChange(value) {
      this.form.personLevel = this.personLevelArr.join(',')
    },
    // 人员详情
    getPersonDetail() {
      this.formLoading = true
      const params = {
        id: this.$route.params.id
      }
      this.detailHomeOldPersonServe(params).then((res) => {
        console.log(res, 'res')
        if (res.code == 200) {
          if (res.data.personLevel != '' && res.data.personLevel != null) {
            this.personLevelArr = res.data.personLevel.split(',')
          } else {
            res.data.personLevel = ''
          }
          if (res.data.zfProjectServiceCategory.length > 0) {
            res.data.zfProjectServiceCategory = res.data.zfProjectServiceCategory[0]
          } else {
            res.data.zfProjectServiceCategory = ''
          }
          this.form = {
            id: this.$route.params.id,
            ...res.data,
            tenant: res.data.tenant.split(',').slice(2),
            censusTenant: res.data.censusTenant.split(',').slice(2)
          }
          console.log(this.form)

          // 如果图片存在，查图片详情
          if (this.form.photo) {
            this.getFileDetail(this.form.photo)
          }

          this.$nextTick(() => {
            this.$refs.censusRegionForm.initRegion()
            this.$refs.regionForm.initRegion()
          })

          this.formLoading = false
          this.creatQrCode()
        }
      })
    },
    getFileDetail(id) {
      this.downloadFile(id).then(res => {
        this.photoUrl = window.URL.createObjectURL(res.data)
      })
    }
  }

}
</script>
<style scoped>
/deep/ .el-collapse {
  border-bottom: none !important;
}
</style>
<style lang="scss" scoped>
.wrapper {
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
  }

  .picture {
    margin-left: 105px;
    margin-bottom: 20px;
  }

  img {
    width: 100px;
    height: 100px;
  }

  &.person-manage-add {
    display: flex;
    flex-direction: column;

    .el-tabs {
      flex: 1;
      overflow: auto;
      margin: 6px 20px;
    }

    .el-collapse {
      padding: 20px;
    }

  }

  .vxe-form {
    padding: 25px 0;
    overflow: auto;
  }

  .my-dropdown1 {
    height: 200px;
    overflow: auto;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    padding: 0 8px;

    li {
      height: 32px;
      line-height: 32px;
      cursor: pointer;
    }
  }
}

.qrcode {
  margin-left: 107px;
}

.add-title {
  height: 80px;
  line-height: 80px;
  margin-left: 60px;
  font-size: 20px;
  color: #fe4066;

  &.expand {
    color: #409eff;
  }
}

.add-title1 {
  display: flex;
  align-items: center;
  margin-left: 40px;
  font-size: 20px;
  color: #fe4066;
  padding-bottom: 20px;

  &.expand {
    color: #409eff;
  }
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-right-10 {
  margin-right: 10px;
}

.avatar-uploader {
  width: 100%;
  height: 100px;
}
</style>
<style lang="scss">
.person-manage-add {
  .el-loading-spinner {
    top: 5% !important;
  }
}

.c-red {
  .el-form-item__label {
    color: #fe4066;
  }

}
</style>

<template>
  <div>
    <div v-show="!showDetail && !showForm" class="table">
      <vxe-grid
          border
          stripe
          resizable
          auto-resize
          :columns="tableColumn"
          :data="tableData"
          :loading="loading"
          header-align="center"
      >
        <template v-slot:personName="{ row }">
          <p class="link-a">{{ row.oldPersonName }}</p>
        </template>
        <template v-slot:elderlyDataType="{ row }">
          <div v-if="row.elderlyDataType ==='ARCHIVE'">适老化改造档案</div>
          <div v-if="row.elderlyDataType ==='BASIC_INFO'">适老化改造基本信息确认</div>
          <div v-if="row.elderlyDataType ==='APPLY'">适老化改造申请</div>
          <div v-if="row.elderlyDataType ==='PERSON_ASSESS'">适老化改造老年人评估</div>
          <div v-if="row.elderlyDataType ==='REQUIRE_CONFIRM'">适老化改造需求确认</div>
          <div v-if="row.elderlyDataType ==='REQUIRE_CONSTRUCT'">适老化改造施工</div>
          <div v-if="row.elderlyDataType ==='REQUIRE_ACCEPT'">适老化改造需求验收</div>
          <div v-if="row.elderlyDataType ==='REFORM_GIVE_UP'">放弃改造承诺书</div>
        </template>
        <template v-slot:operation="{ row }">
          <div class="handle-list">
            <div v-if="!row.isMore" class="link-a" @click="goDetail(row)">详情</div>
            <div class="link-a margin-left-20" @click="deleteList(row)">删除</div>
            <!--            <div v-if="row.isMore" class="link-a" @click="updateList(row)">修改</div>-->
            <!--            <div v-if="row.isMore" class="link-a margin-left-20" @click="deleteList(row)">删除</div>-->
            <!--            <img v-if="!row.isMore" src="@/assets/images/show-more.png" class="handle-image" @click="showMore(row)">-->
            <!--            <img v-if="row.isMore" src="@/assets/images/show-less.png" class="handle-image" @click="showLess(row)">-->
          </div>
        </template>
      </vxe-grid>
    </div>
    <!-- 分页 -->
    <div v-show="!showDetail && !showForm" class="page">
      <vxe-pager
          :current-page.sync="tablePage.currentPage"
          :page-size.sync="tablePage.pageSize"
          :page-sizes="tablePage.pageSizes"
          :total="tablePage.totalResult"
          :layouts="layouts"
          @page-change="pageChange"
      />
    </div>

    <!--适老化改造申请详情-->
    <table v-if="elderlyDataType === 'APPLY' && showDetail" class="table">
      <tbody>
      <tr>
        <td colspan="5" align="center" class="caption position-relation">
          适老化改造申请
          <div class="export-button" @click.stop="exportData(tableDetail.id,elderlyDataType,'适老化改造申请')">导出
          </div>
        </td>
      </tr>
      <tr>
        <td class="td1111">
          <div class="userimg float-left">
            <img
                width="107"
                height="135"
                :src="tableDetail.photoInfo.url"
                onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'"
            >
          </div>
        </td>
        <td colspan="4">
          <div class="tableinfo">
            <div class="frow">
              <div class="fcol w200">
                <label> <span class="font14">姓</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >名：</span>
                </label> <label><span class="font14"><a>{{ tableDetail.name }}</a></span></label>
              </div>
              <div class="fcol w400">
                <label> <span class="font14">性</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >别：</span>
                </label> <label><span class="font14">{{ tableDetail.sex }}</span></label>
              </div>
              <div class="fcol">
                <label>户籍所在地：</label> <label><span class="font14">
                    {{ tableDetail.censusProvince }}{{ tableDetail.censusCity }}{{ tableDetail.censusArea }}
                    {{ tableDetail.censusStreet }}{{ tableDetail.censusCommunity }}
                    {{ tableDetail.censusAddress }}</span></label>
              </div>
            </div>
            <div class="frow">
              <div class="fcol w200">
                <label> <span class="font14">年</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >龄：</span>
                </label> <label><span class="font14">{{ tableDetail.age }}</span></label>
              </div>
              <div class="fcol w400">
                <label>身份证号：</label>
                <label><span class="font14">{{ tableDetail.idNum }}</span></label>
              </div>
              <div class="fcol">
                <label>联系方式：</label> <label><span class="font14"> {{ tableDetail.contract }}</span></label>
              </div>
            </div>
            <div class="frow">
              <div class="fcol w200">
                <label>自理能力：</label>
                <label><span class="font14">{{ tableDetail.selfCareAbiyName }}</span></label>
              </div>
              <div class="fcol ">
                <label> <span class="font14">类</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >别：</span></label>
                <label><span class="font14">{{ tableDetail.personTypeNames }}</span></label>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">申请改造住宅<br>地址：</span></td>
        <td class="text-left" colspan="2">
            <span class="font14">{{ tableDetail.censusProvince }}{{ tableDetail.censusCity }}{{
                tableDetail.censusArea
              }}
              {{ tableDetail.censusStreet }}{{ tableDetail.censusCommunity }}
              {{ tableDetail.censusAddress }}
            </span></td>
        <td class="text-right"><span class="font14">住宅类型：</span></td>
        <td class="text-left"><span class="font14">{{ tableDetail.houseTypeName }}</span></td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">住宅情况</span></td>
        <td class="text-left" colspan="4">
          <span class="font14">{{ tableDetail.houseBelongOwn === 1 ? '自有' : '非自有' }}</span></td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">联系人姓名：</span></td>
        <td class="text-left" colspan="2"><span class="font14">{{ tableDetail.emergencyContactName }}</span></td>
        <td class="text-right"><span class="font14">联系人关系：</span></td>
        <td class="text-left">
          <span class="font14">{{ tableDetail.emergencyContactRelateShip }}</span><br>
          <!-- <span class="font14">-</span> -->
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">联络人电话：</span></td>
        <td class="text-left" colspan="4">
          <span class="font14">{{ tableDetail.emergencyContactTel }}</span>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">经纬度</span></td>
        <td class="text-left" colspan="4">
            <span
                v-if="tableDetail.latitude !== '' && tableDetail.latitude !== null"
                class="font14"
            >{{ tableDetail.latitude }},{{ tableDetail.latitude }}</span>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">备注</span></td>
        <td class="text-left" colspan="4">
          <span class="font14">{{ tableDetail.remark }}</span></td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">房屋改造照片:</span></td>
        <td class="text-left" colspan="4">
          <div class="userimg float-left">
            <image-show :image-list="tableDetail.imgInfoList"/>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">证明材料:</span></td>
        <td class="text-left" colspan="4">
          <div class="userimg float-left">
            <image-show :image-list="tableDetail.materialFileInfoList"/>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">老人签名:</span></td>
        <td class="text-left" colspan="4">
          <div class="userimg float-left">
            <image-show :image-list="tableDetail.signInfoList"/>
          </div>
          <div>本人及组织承诺对以上评估结果负责，愿意承担因评估不当产生的一切不良后果</div>
        </td>
      </tr>
      </tbody>
    </table>

    <!--适老化改造信息确认详情-->
    <table v-if="elderlyDataType === 'BASIC_INFO' && showDetail" class="table">
      <tbody>
      <tr>
        <td colspan="5" align="center" class="caption position-relation">
          适老化改造信息确认
          <!--          <div class="export-button" @click.stop="exportData(tableDetail.id,elderlyDataType,'适老化改造信息确认')">-->
          <!--            导出-->
          <!--          </div>-->
        </td>
      </tr>
      <tr>
        <td class="td1111">
          <div class="userimg float-left">
            <img
                width="107"
                height="135"
                :src="tableDetail.photoInfo.url"
                onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'"
            >
          </div>
        </td>
        <td colspan="4">
          <div class="tableinfo">
            <div class="frow">
              <div class="fcol w200">
                <label> <span class="font14">姓</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >名：</span>
                </label> <label><span class="font14"><a>{{ tableDetail.name }}</a></span></label>
              </div>
              <div class="fcol w400">
                <label> <span class="font14">性</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >别：</span>
                </label> <label><span class="font14">{{ tableDetail.sex }}</span></label>
              </div>
              <div class="fcol">
                <label>户籍所在地：</label> <label><span class="font14">
                    {{ tableDetail.censusProvince }}{{ tableDetail.censusCity }}{{ tableDetail.censusArea }}
                    {{ tableDetail.censusStreet }}{{ tableDetail.censusCommunity }}
                    {{ tableDetail.censusAddress }}</span></label>
              </div>
            </div>
            <div class="frow">
              <div class="fcol w200">
                <label> <span class="font14">年</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >龄：</span>
                </label> <label><span class="font14">{{ tableDetail.age }}</span></label>
              </div>
              <div class="fcol w400">
                <label>身份证号：</label>
                <label><span class="font14">{{ tableDetail.idNum }}</span></label>
              </div>
              <div class="fcol">
                <label>联系方式：</label> <label><span class="font14"> {{ tableDetail.contract }}</span></label>
              </div>
            </div>
            <div class="frow">

              <div class="fcol w200">
                <label>自理能力：</label>
                <label><span class="font14">{{ tableDetail.selfCareAbiyName }}</span></label>
              </div>
              <div class="fcol ">
                <label> <span class="font14">类</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >别：</span></label>
                <label><span class="font14">{{ tableDetail.personTypeNames }}</span></label>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">申请改造住宅<br>地址：</span></td>
        <td class="text-left" colspan="2">
            <span class="font14">{{ tableDetail.censusProvince }}{{ tableDetail.censusCity }}{{
                tableDetail.censusArea
              }}
              {{ tableDetail.censusStreet }}{{ tableDetail.censusCommunity }}
              {{ tableDetail.censusAddress }}
            </span></td>
        <td class="text-right"><span class="font14">住宅类型：</span></td>
        <td class="text-left"><span class="font14">{{ tableDetail.houseTypeName }}</span></td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">住宅情况</span></td>
        <td class="text-left" colspan="4">
          <span class="font14">{{ tableDetail.houseBelongOwn === 1 ? '自有' : '非自有' }}</span></td>
      </tr>

      <tr>
        <td class="text-right"><span class="font14">联系人姓名：</span></td>
        <td class="text-left" colspan="2"><span class="font14">{{ tableDetail.emergencyContactName }}</span></td>
        <td class="text-right"><span class="font14">联系人关系：</span></td>
        <td class="text-left">
          <span class="font14">{{ tableDetail.emergencyContactRelateShip }}</span><br>
          <!-- <span class="font14">-</span> -->
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">联络人电话：</span></td>
        <td class="text-left" colspan="4">
          <span class="font14">{{ tableDetail.emergencyContactTel }}</span>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">经纬度</span></td>
        <td class="text-left" colspan="4">
            <span
                v-if="tableDetail.latitude !== '' && tableDetail.latitude !== null"
                class="font14"
            >{{ tableDetail.latitude }},{{ tableDetail.latitude }}</span>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">备注</span></td>
        <td class="text-left" colspan="4">
          <span class="font14">{{ tableDetail.remark }}</span></td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">房屋改造照片:</span></td>
        <td class="text-left" colspan="4">
          <div class="userimg float-left">
            <image-show :image-list="tableDetail.imgInfoList"/>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">证明材料:</span></td>
        <td class="text-left" colspan="4">
          <div class="userimg float-left">
            <image-show :image-list="tableDetail.materialFileInfoList"/>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">老人签名:</span></td>
        <td class="text-left" colspan="4">
          <div class="userimg float-left">
            <image-show :image-list="tableDetail.signInfoList"/>
          </div>
          <div>本人及组织承诺对以上评估结果负责，愿意承担因评估不当产生的一切不良后果</div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">信息确认日期</span></td>
        <td class="text-left" colspan="4">
          <span class="font14">{{ tableDetail.basicInfoDate}}</span></td>
      </tr>
      </tbody>
    </table>
    <!--老人评估详情-->
    <table v-if="elderlyDataType === 'PERSON_ASSESS' && showDetail" class="table">
      <tbody>
      <tr>
        <td colspan="5" align="center" class="caption position-relation">
          适老化改造评估
          <div class="export-button" @click.stop="exportData(tableDetail.id,elderlyDataType,'适老化改造评估')">导出
          </div>
        </td>
      </tr>
      <tr>
        <td class="td1111">
          <div class="userimg float-left">
            <img
                width="107"
                height="135"
                :src="tableDetail.photoInfo.url"
                onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'"
            >
          </div>
        </td>
        <td colspan="4">
          <div class="tableinfo">
            <div class="frow">
              <div class="fcol w400">
                <label> <span class="font14">姓</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >名：</span>
                </label> <label><span class="font14"><a>{{ tableDetail.name }}</a></span></label>
              </div>
              <div class="fcol w400">
                <label> <span class="font14">性</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >别：</span>
                </label> <label><span class="font14">{{ tableDetail.sex }}</span></label>
              </div>
              <div class="fcol">
                <label>户籍所在地：</label> <label><span class="font14">
                    {{ tableDetail.censusProvince }}{{ tableDetail.censusCity }}{{ tableDetail.censusArea }}
                    {{ tableDetail.censusStreet }}{{ tableDetail.censusCommunity }}
                    {{ tableDetail.censusAddress }}</span></label>
              </div>
            </div>
            <div class="frow">
              <div class="fcol w400">
                <label> <span class="font14">年</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >龄：</span>
                </label> <label><span class="font14">{{ tableDetail.age }}</span></label>
              </div>
              <div class="fcol w400">
                <label>身份证号：</label>
                <label><span class="font14">{{ tableDetail.idNum }}</span></label>
              </div>
              <div class="fcol">
                <label>联系方式：</label> <label><span class="font14"> {{ tableDetail.contract }}</span></label>
              </div>
            </div>
            <div class="frow">

              <div class="fcol w400">
                <label>评估编号：</label> <label><span class="font14"> {{ tableDetail.reformNo }}</span></label>
              </div>
              <div class="fcol ">
                <label> <span class="font14">类</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >别：</span></label>
                <label><span class="font14">{{ tableDetail.personTypeNames }}</span></label>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-left" rowspan="7" style="vertical-align: top;text-align: center;"><span
            class="font14"
        >基本信息</span></td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">住宅地址</span></td>
        <td class="text-left">
            <span class="font14">{{ tableDetail.censusProvince }}{{ tableDetail.censusCity }}{{
                tableDetail.censusArea
              }}
              {{ tableDetail.censusStreet }}{{ tableDetail.censusCommunity }}
              {{ tableDetail.censusAddress }}
            </span></td>
        <td class="text-right"><span class="font14">婚姻状况</span></td>
        <td class="text-left"><span class="font14">{{ tableDetail.marriageName }}</span></td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">政治面貌</span></td>
        <td class="text-left">
          <span class="font14">{{ tableDetail.politicalStatusName }}</span></td>
        <td class="text-right"><span class="font14">居住情况</span></td>
        <td class="text-left"><span class="font14">{{ tableDetail.livingName }}</span></td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">社保卡号</span></td>
        <td class="text-left">
          <span class="font14">{{ tableDetail.socialSecurityCardNum }}</span></td>
        <td class="text-right"><span class="font14">退休前职业</span></td>
        <td class="text-left"><span class="font14">{{ tableDetail.beforeJobName }}</span></td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">经济来源</span></td>
        <td class="text-left">
          <span class="font14">{{ tableDetail.incomeSourceName }}</span></td>
        <td class="text-right"><span class="font14">住房性质</span></td>
        <td class="text-left"><span class="font14">{{ tableDetail.houseBelongOwn === 1 ? '自有' : '非自有' }}</span>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">住宅类型</span></td>
        <td class="text-left">
          <span class="font14">{{ tableDetail.houseTypeName }}</span></td>
        <td class="text-right"><span class="font14">文化程度</span></td>
        <td class="text-left"><span class="font14">{{ tableDetail.educationName }}</span></td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">宗教信仰</span></td>
        <td class="text-left">
          <span class="font14">{{ tableDetail.religious }}</span></td>
        <td class="text-right"><span class="font14">参保情况</span></td>
        <td class="text-left"><span class="font14">{{ tableDetail.insuranceName }}</span></td>
      </tr>
      <tr>
        <td class="text-left" rowspan="3" style="vertical-align: top;text-align: center;"><span
            class="font14"
        >联系人情况</span></td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">联络人姓名</span></td>
        <td class="text-left">
          <span class="font14">{{ tableDetail.emergencyContactName }}</span></td>
        <td class="text-right"><span class="font14">联络人电话</span></td>
        <td class="text-left"><span class="font14">{{ tableDetail.emergencyContactTel }}</span></td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">联络人关系</span></td>
        <td class="text-left" colspan="3">
          <span class="font14">{{ tableDetail.emergencyContactRelateShip }}</span></td>
      </tr>

      <tr>
        <td class="text-left" rowspan="6" style="vertical-align: top;text-align: center;"><span
            class="font14"
        >家庭成员评估</span></td>
      </tr>

      <tr>
        <td class="text-right"><span class="font14">是否残疾</span></td>
        <td class="text-left" colspan="3">
          <span class="font14">{{ tableDetail.disability === 1 ? '是' : '否' }}</span></td>
      </tr>
      <tr v-for="(item,index) in tableDetail.accessDetail" v-if="index <=7 && index%2 === 0" :key="index">
        <td class="text-right"><span class="font14">{{ item.name }}：</span></td>
        <td class="text-left">
          <span class="font14">{{ item.showValue }}</span>
        </td>
        <td class="text-right"><span class="font14">{{ tableDetail.accessDetail[index + 1].name }}：</span></td>
        <td class="text-left"><span class="font14">{{ tableDetail.accessDetail[index + 1].showValue }}</span></td>
      </tr>
      <tr>
        <td class="text-left" rowspan="4" style="vertical-align: top;text-align: center;"><span
            class="font14"
        >近一年意外事件</span></td>
      </tr>
      <tr
          v-for="(item,index) in tableDetail.accessDetail"
          v-if="index <= 12 && index >=8 && index%2 === 0"
          :key="index"
      >
        <td class="text-right"><span class="font14">{{ item.name }}：</span></td>
        <td class="text-left">
          <span class="font14">{{ item.showValue }}</span>
        </td>
        <td class="text-right"><span class="font14">{{ tableDetail.accessDetail[index + 1].name }}：</span></td>
        <td class="text-left"><span class="font14">{{ tableDetail.accessDetail[index + 1].showValue }}</span></td>
      </tr>
      <tr>
        <td class="text-left" rowspan="10" style="vertical-align: top;text-align: center;"><span
            class="font14"
        >健康状况</span></td>
      </tr>
      <tr
          v-for="(item,index) in tableDetail.accessDetail"
          v-if="index <= 31 && index >=13 && index%2 === 0"
          :key="index"
      >
        <td class="text-right"><span class="font14">{{ item.name }}：</span></td>
        <td class="text-left">
          <span class="font14">{{ item.showValue }}</span>
        </td>
        <td class="text-right"><span class="font14">{{ tableDetail.accessDetail[index + 1].name }}：</span></td>
        <td class="text-left"><span class="font14">{{ tableDetail.accessDetail[index + 1].showValue }}</span></td>
      </tr>
      <tr v-for="(item,index) in tableDetail.accessDetail[32].options" :key="'8'+index">
        <td
            v-if="index === 0"
            class="text-center"
            style="vertical-align: top"
            :rowspan="tableDetail.accessDetail[32].options.length"
        ><span
            class="font14"
        >{{ tableDetail.accessDetail[32].name }}：</span></td>
        <td class="text-left" colspan="3">
          <span class="font14">{{ item.value }}</span>
        </td>
        <td class="text-center"><span class="font14">{{ item.showValue }}</span></td>
      </tr>

      <tr v-for="(item,index) in tableDetail.accessDetail[33].options" :key="'29'+index">
        <td
            v-if="index === 0"
            class="text-center"
            style="vertical-align: top"
            :rowspan="tableDetail.accessDetail[33].options.length"
        ><span
            class="font14"
        >{{ tableDetail.accessDetail[33].name }}：</span></td>
        <td class="text-left" colspan="3">
          <span class="font14">{{ item.value }}</span>
        </td>
        <td class="text-center"><span class="font14">{{ item.showValue }}</span></td>
      </tr>
      <tr v-for="(item,index) in tableDetail.accessDetail[34].options" :key="'34'+index">
        <td
            v-if="index === 0"
            class="text-center"
            style="vertical-align: top"
            :rowspan="tableDetail.accessDetail[34].options.length"
        ><span
            class="font14"
        >{{ tableDetail.accessDetail[34].name }}：</span></td>
        <td class="text-left" colspan="3">
          <span class="font14">{{ item.value }}</span>
        </td>
        <td class="text-center"><span class="font14">{{ item.showValue }}</span></td>
      </tr>
      <tr v-for="(item,index) in tableDetail.accessDetail[35].options" :key="'35'+index">
        <td
            v-if="index === 0"
            class="text-center"
            style="vertical-align: top"
            :rowspan="tableDetail.accessDetail[35].options.length"
        ><span
            class="font14"
        >{{ tableDetail.accessDetail[35].name }}：</span></td>
        <td class="text-left" colspan="3">
          <span class="font14">{{ item.value }}</span>
        </td>
        <td class="text-center"><span class="font14">{{ item.showValue }}</span></td>
      </tr>

      <tr>
        <td class="text-left" rowspan="3" style="vertical-align: top;text-align: center;"><span class="font14">居家环境安全评估情况</span>
        </td>
      </tr>
      <tr v-for="(item,index) in tableDetail.accessDetail" v-if="index > 35 && index%2 === 0" :key="index">
        <td class="text-right"><span class="font14">{{ item.name }}：</span></td>
        <td class="text-left">
          <span class="font14">{{ item.showValue }}</span>
        </td>
        <td class="text-right"><span class="font14">{{ tableDetail.accessDetail[index + 1].name }}：</span></td>
        <td class="text-left" colspan="2">
          <span class="font14">{{ tableDetail.accessDetail[index + 1].showValue }}</span>
        </td>
      </tr>
      <tr v-if="tableDetail.typeList.length >0 || tableDetail.productList.length >0">
        <td
            class="text-left"
            :rowspan="tableDetail.typeList.length + tableDetail.productList.length +1"
            style="vertical-align: top;text-align: center;"
        ><span class="font14">用户对居家环境安全有何需求</span></td>
      </tr>
      <tr v-if="tableDetail.typeList.length >0">
        <td colspan="4" style="border: 0;padding: 0">
          <table style="width: 100%">
            <tbody>
            <tr style="background-color: #F8F8F9">
              <td align="center">改造项目类别</td>
              <td align="center">改造项目</td>
              <td align="center">改造内容</td>
              <td align="center">改造数量</td>
              <td align="center">费用/元</td>
            </tr>
            <tr v-for="(item,index) in tableDetail.typeList" :key="index">
              <td align="center">{{ item.reformProjectDetail.category }}</td>
              <td align="center">{{ item.reformProjectDetail.name }}</td>
              <td align="center">{{ item.reformProjectDetail.desc }}</td>
              <td align="center">{{ item.count }}</td>
              <td align="center">{{ item.total }}</td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr v-if="tableDetail.productList.length >0">
        <td colspan="4" style="border: 0;padding: 0">
          <table style="width: 100%">
            <tbody>
            <tr style="background-color: #F8F8F9">
              <td align="center">适老化产品类别</td>
              <td align="center">图片</td>
              <td align="center">型号</td>
              <td align="center">名称</td>
              <td align="center">参数</td>
              <td align="center">功能</td>
              <td align="center">厂家</td>
              <td align="center">数量</td>
              <td align="center">费用/元</td>
            </tr>
            <tr v-for="(item,index) in tableDetail.productList" :key="index">
              <td align="center" style="width:120px">
                {{ item.reformProductDetail.categoryLevel1 }}{{ item.reformProductDetail.categoryLevel2 }}
              </td>
              <td align="center" style="width:100px">
                <image-show :image-list="item.reformProductDetail.pictureInfoList"/>
              </td>
              <td align="center" style="width:100px">{{ item.reformProductDetail.name }}</td>
              <td align="center" style="width:70px">{{ item.reformProductDetail.model }}</td>
              <td align="center">{{ item.reformProductDetail.parameter }}</td>
              <td align="center">{{ item.reformProductDetail.func }}</td>
              <td align="center" style="width:70px">{{ item.reformProductDetail.manufacturer }}</td>
              <td align="center" style="width:70px">{{ item.count }}</td>
              <td align="center" style="width:70px">{{ item.total }}</td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr/>
      <tr>
        <td class="text-right"><span class="font14">评估人员：</span></td>
        <td class="text-left" colspan="2">
          <span class="font14"><a>{{ tableDetail.workerName }}</a></span>
        </td>
        <td class="text-right"><span class="font14">评估时间：</span></td>
        <td class="text-left" colspan="2">
          <span class="font14">{{ tableDetail.assessDate }}</span>
        </td>
      </tr>
      <tr/>
      <tr>
        <td class="text-right"><span class="font14">居家环境适老<br>化安全改善建<br>议：</span></td>
        <td class="text-left" colspan="4">
          <span class="font14">{{ tableDetail.remark }}</span>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">评估图片:</span></td>
        <td class="text-left" colspan="4">
          <div class="userimg float-left">
            <image-show :image-list="tableDetail.imgInfoList"/>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">评估人员签字:</span></td>
        <td class="text-left" colspan="4">
          <div class="userimg float-left">
            <image-show :image-list="tableDetail.workerSignInfoList"/>
          </div>
          <div>本人及组织承诺对以上评估结果负责，愿意承担因评估不当产生的一切不良后果</div>
        </td>
      </tr>
      </tbody>
    </table>

    <!--适老化改造需求评估确认详情-->
    <table v-if="elderlyDataType === 'REQUIRE_CONFIRM' && showDetail" class="table">
      <tbody>
      <tr>
        <td colspan="5" align="center" class="caption position-relation">
          适老化改造需求评估确认
          <div class="export-button" @click.stop="exportData(tableDetail.id,elderlyDataType,'适老化改造需求评估确认')">
            导出
          </div>
        </td>
      </tr>
      <tr>
        <td class="td1111">
          <div class="userimg float-left">
            <img
                width="107"
                height="135"
                :src="tableDetail.photoInfo.url"
                onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'"
            >
          </div>
        </td>
        <td colspan="4">
          <div class="tableinfo">
            <div class="frow">
              <div class="fcol w200">
                <label> <span class="font14">姓</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >名：</span>
                </label> <label><span class="font14"><a>{{ tableDetail.name }}</a></span></label>
              </div>
              <div class="fcol w400">
                <label> <span class="font14">性</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >别：</span>
                </label> <label><span class="font14">{{ tableDetail.sex }}</span></label>
              </div>
              <div class="fcol">
                <label>户籍所在地：</label> <label><span class="font14">
                    {{ tableDetail.censusProvince }}{{ tableDetail.censusCity }}{{ tableDetail.censusArea }}
                    {{ tableDetail.censusStreet }}{{ tableDetail.censusCommunity }}
                    {{ tableDetail.censusAddress }}</span></label>
              </div>
            </div>
            <div class="frow">
              <div class="fcol w200">
                <label> <span class="font14">年</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >龄：</span>
                </label> <label><span class="font14">{{ tableDetail.age }}</span></label>
              </div>
              <div class="fcol w400">
                <label>身份证号：</label>
                <label><span class="font14">{{ tableDetail.idNum }}</span></label>
              </div>
              <div class="fcol">
                <label>联系方式：</label> <label><span class="font14"> {{ tableDetail.contract }}</span></label>
              </div>
            </div>
            <div class="frow">
              <div class="fcol w200">
                <label>自理能力：</label> <label><span class="font14"> {{ tableDetail.selfCareAbiyName }}</span></label>
              </div>
              <div class="fcol ">
                <label> <span class="font14">类</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >别：</span></label>
                <label><span class="font14">{{ tableDetail.personTypeNames }}</span></label>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">申请改造住宅<br>地址：</span></td>
        <td class="text-left" colspan="4">
            <span class="font14">{{ tableDetail.censusProvince }}{{ tableDetail.censusCity }}{{
                tableDetail.censusArea
              }}
              {{ tableDetail.censusStreet }}{{ tableDetail.censusCommunity }}
              {{ tableDetail.censusAddress }}
            </span></td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">服务机构：</span></td>
        <td class="text-left" colspan="2"><span class="font14">{{ tableDetail.workerCompName }}</span></td>
      </tr>
      <tr v-if="tableDetail.typeList.length >0">
        <td colspan="5" style="border: 0;padding: 0">
          <table style="width: 100%">
            <tbody>
            <tr style="background-color: #F8F8F9">
              <td align="center">改造项目类别</td>
              <td align="center">改造项目</td>
              <td align="center">改造内容</td>
              <td align="center">改造数量</td>
              <td align="center">费用/元</td>
            </tr>
            <tr v-for="(item,index) in tableDetail.typeList" :key="index">
              <td align="center">{{ item.reformProjectDetail.category }}</td>
              <td align="center">{{ item.reformProjectDetail.name }}</td>
              <td align="center">{{ item.reformProjectDetail.desc }}</td>
              <td align="center">{{ item.count }}</td>
              <td align="center">{{ item.total }}</td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr v-if="tableDetail.productList.length >0">
        <td colspan="5" style="border: 0;padding: 0">
          <table style="width: 100%">
            <tbody>
            <tr style="background-color: #F8F8F9">
              <td align="center">适老化产品类别</td>
              <td align="center">图片</td>
              <td align="center">型号</td>
              <td align="center">名称</td>
              <td align="center">参数</td>
              <td align="center">功能</td>
              <td align="center">厂家</td>
              <td align="center">数量</td>
              <td align="center">费用/元</td>
            </tr>
            <tr v-for="(item,index) in tableDetail.productList" :key="index">
              <td align="center" style="width:120px">
                {{ item.reformProductDetail.categoryLevel1 }}{{ item.reformProductDetail.categoryLevel2 }}
              </td>
              <td align="center" style="width:100px">
                <image-show :image-list="item.reformProductDetail.pictureInfoList"/>
              </td>
              <td align="center" style="width:100px">{{ item.reformProductDetail.name }}</td>
              <td align="center" style="width:70px">{{ item.reformProductDetail.model }}</td>
              <td align="center">{{ item.reformProductDetail.parameter }}</td>
              <td align="center">{{ item.reformProductDetail.func }}</td>
              <td align="center" style="width:70px">{{ item.reformProductDetail.manufacturer }}</td>
              <td align="center" style="width:70px">{{ item.count }}</td>
              <td align="center" style="width:70px">{{ item.total }}</td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr style="background-color: #F8F8F9">
        <td class="text-right"><span class="font14">增项费用</span></td>
        <td class="text-left" colspan="4">
          <span class="font14">{{ tableDetail.addList }}</span></td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">改造合计费用</span></td>
        <td class="text-left" colspan="4">
          <span class="font14">{{ tableDetail.total }}元</span></td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">需求评估确认</span></td>
        <td class="text-left" colspan="4">
          <div class="userimg float-left">
            <image-show :image-list="tableDetail.confirmPaperList"/>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">评估人员签名:</span></td>
        <td class="text-left" colspan="4">
          <div class="userimg float-left">
            <image-show :image-list="tableDetail.workerSignInfoList"/>
          </div>
          <div>本人及组织承诺对以上评估结果负责，愿意承担因评估不当产生的一切不良后果</div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">评估需求确认日期</span></td>
        <td class="text-left" colspan="4">
          <span class="font14">{{ tableDetail.requireConfirmDate}}</span></td>
      </tr>
      </tbody>
    </table>

    <!--适老化改造施工详情-->
    <table v-if="elderlyDataType === 'REQUIRE_CONSTRUCT' && showDetail" class="table">
      <tbody>
      <tr>
        <td colspan="5" align="center" class="caption position-relation">
          适老化改造施工
          <div class="export-button" @click.stop="exportData(tableDetail.id,elderlyDataType,'适老化改造施工')">导出
          </div>
        </td>
      </tr>
      <tr>
        <td class="td1111">
          <div class="userimg float-left">
            <img
                width="107"
                height="135"
                :src="tableDetail.photoInfo.url"
                onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'"
            >
          </div>
        </td>
        <td colspan="4">
          <div class="tableinfo">
            <div class="frow">
              <div class="fcol w200">
                <label> <span class="font14">姓</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >名：</span>
                </label> <label><span class="font14"><a>{{ tableDetail.name }}</a></span></label>
              </div>
              <div class="fcol w400">
                <label> <span class="font14">性</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >别：</span>
                </label> <label><span class="font14">{{ tableDetail.sex }}</span></label>
              </div>
              <div class="fcol">
                <label>户籍所在地：</label> <label><span class="font14">
                    {{ tableDetail.censusProvince }}{{ tableDetail.censusCity }}{{ tableDetail.censusArea }}
                    {{ tableDetail.censusStreet }}{{ tableDetail.censusCommunity }}
                    {{ tableDetail.censusAddress }}</span></label>
              </div>
            </div>
            <div class="frow">
              <div class="fcol w200">
                <label> <span class="font14">年</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >龄：</span>
                </label> <label><span class="font14">{{ tableDetail.age }}</span></label>
              </div>
              <div class="fcol w400">
                <label>身份证号：</label>
                <label><span class="font14">{{ tableDetail.idNum }}</span></label>
              </div>
              <div class="fcol">
                <label>联系方式：</label> <label><span class="font14"> {{ tableDetail.contract }}</span></label>
              </div>
            </div>
            <div class="frow">

              <div class="fcol w200">
                <label>自理能力：</label>
                <label><span class="font14">{{ tableDetail.selfCareAbiyName }}</span></label>
              </div>
              <div class="fcol ">
                <label> <span class="font14">类</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >别：</span></label>
                <label><span class="font14">{{ tableDetail.personTypeNames }}</span></label>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">申请改造住宅<br>地址：</span></td>
        <td class="text-left" colspan="4">
            <span class="font14">{{ tableDetail.censusProvince }}{{ tableDetail.censusCity }}{{
                tableDetail.censusArea
              }}
              {{ tableDetail.censusStreet }}{{ tableDetail.censusCommunity }}
              {{ tableDetail.censusAddress }}
            </span></td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">开工日期</span></td>
        <td class="text-left" colspan="2">
          <span class="font14">{{ tableDetail.startDate.replace(' 00:00:00', '') }} </span></td>
        <td class="text-right"><span class="font14">竣工日期</span></td>
        <td class="text-left">
          <span class="font14">{{ tableDetail.endDate.replace(' 00:00:00', '') }}</span></td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">服务机构：</span></td>
        <td class="text-left" colspan="2"><span class="font14">{{ tableDetail.workerCompName }}</span></td>
      </tr>
      <tr v-if="tableDetail.project.length >0">
        <td colspan="5" style="border: 0;padding: 0">
          <table style="width: 100%">
            <tbody>
            <tr style="background-color: #F8F8F9">
              <td align="center">改造项目类别</td>
              <td align="center">改造项目</td>
              <td align="center">改造内容</td>
              <td align="center">改造数量</td>
              <td align="center">施工前</td>
<!--              <td align="center">施工中</td>-->
              <td align="center">施工后</td>
            </tr>
            <tr v-for="(item,index) in tableDetail.project" :key="index">
              <td align="center">{{ item.reformProjectDetail.category }}</td>
              <td align="center">{{ item.reformProjectDetail.name }}</td>
              <td align="center">{{ item.reformProjectDetail.desc }}</td>
              <td align="center">{{ item.count }} {{ item.beforeConstructImgList }}</td>
              <td align="center" style="width:100px">
                <image-show :image-list="item.imgs.beforeConstructImgList"/>
              </td>
<!--              <td align="center" style="width:100px">-->
<!--                <image-show :image-list="item.imgs.inConstructImgList"/>-->
<!--              </td>-->
              <td align="center" style="width:100px">
                <image-show :image-list="item.imgs.afterConstructImgList"/>
              </td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr v-if="tableDetail.product.length >0">
        <td colspan="5" style="border: 0;padding: 0">
          <table style="width: 100%">
            <tbody>
            <tr style="background-color: #F8F8F9">
              <td align="center">适老化产品类别</td>
              <td align="center">图片</td>
              <td align="center">型号</td>
              <td align="center">名称</td>
              <td align="center">参数</td>
              <td align="center">功能</td>
              <td align="center">厂家</td>
              <td align="center">数量</td>
              <td align="center">施工前</td>
<!--              <td align="center">施工中</td>-->
              <td align="center">施工后</td>
            </tr>
            <tr v-for="(item,index) in tableDetail.product" :key="index">
              <td align="center" style="width:120px">
                {{ item.reformProductDetail.categoryLevel1 }}{{ item.reformProductDetail.categoryLevel2 }}
              </td>
              <td align="center" style="width:100px">
                <image-show :image-list="item.reformProductDetail.pictureInfoList"/>
              </td>
              <td align="center" style="width:100px">{{ item.reformProductDetail.name }}</td>
              <td align="center" style="width:70px">{{ item.reformProductDetail.model }}</td>
              <td align="center">{{ item.reformProductDetail.parameter }}</td>
              <td align="center">{{ item.reformProductDetail.func }}</td>
              <td align="center" style="width:70px">{{ item.reformProductDetail.manufacturer }}</td>
              <td align="center" style="width:70px">{{ item.count }}</td>
              <td align="center" style="width:100px">
                <image-show :image-list="item.imgs.beforeConstructImgList"/>
              </td>
<!--              <td align="center" style="width:100px">-->
<!--                <image-show :image-list="item.imgs.inConstructImgList"/>-->
<!--              </td>-->
              <td align="center" style="width:100px">
                <image-show :image-list="item.imgs.afterConstructImgList"/>
              </td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">前后对比档案:</span></td>
        <td class="text-left" colspan="4">
          <div class="userimg float-left">
            <image-show :image-list="tableDetail.constructPaperList"/>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">施工方案设计<br>图:</span></td>
        <td class="text-left" colspan="4">
          <div class="userimg float-left">
            <image-show :image-list="tableDetail.designPaperList"/>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">设施维护与安<br>全协议书:</span></td>
        <td class="text-left" colspan="4">
          <div class="userimg float-left">
            <image-show :image-list="tableDetail.fmSaPaperList"/>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">老人签名</span></td>
        <td class="text-left" colspan="4">
          <div class="userimg float-left">
            <image-show :image-list="tableDetail.signInfoList"/>
          </div>
          <div>本人及组织承诺对以上评估结果负责，愿意承担因评估不当产生的一切不良后果</div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">施工人员签字</span></td>
        <td class="text-left" colspan="4">
          <div class="userimg float-left">
            <image-show :image-list="tableDetail.workerSignInfoList"/>
          </div>
          <div>本人及组织承诺对以上评估结果负责，愿意承担因评估不当产生的一切不良后果</div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">施工人员</span></td>
        <td class="text-left" colspan="4">
          <span class="font14">{{ tableDetail.workerName }}</span></td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">备注</span></td>
        <td class="text-left" colspan="4">
          <span class="font14">{{ tableDetail.remark }}</span></td>
      </tr>
      </tbody>
    </table>

    <!--适老化改造需求确认验收详情-->
    <table v-if="elderlyDataType === 'REQUIRE_ACCEPT' && showDetail" class="table">
      <tbody>
      <tr>
        <td colspan="5" align="center" class="caption position-relation">
          适老化改造验收
          <div class="export-button" @click.stop="exportData(tableDetail.id,elderlyDataType,'适老化改造验收')">导出
          </div>
        </td>
      </tr>
      <tr>
        <td class="td1111">
          <div class="userimg float-left">
            <img
                width="107"
                height="135"
                :src="tableDetail.photoInfo.url"
                onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'"
            >
          </div>
        </td>
        <td colspan="4">
          <div class="tableinfo">
            <div class="frow">
              <div class="fcol w200">
                <label> <span class="font14">姓</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >名：</span>
                </label> <label><span class="font14"><a>{{ tableDetail.name }}</a></span></label>
              </div>
              <div class="fcol w400">
                <label> <span class="font14">性</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >别：</span>
                </label> <label><span class="font14">{{ tableDetail.sex }}</span></label>
              </div>
              <div class="fcol">
                <label>户籍所在地：</label> <label><span class="font14">
                    {{ tableDetail.censusProvince }}{{ tableDetail.censusCity }}{{ tableDetail.censusArea }}
                    {{ tableDetail.censusStreet }}{{ tableDetail.censusCommunity }}
                    {{ tableDetail.censusAddress }}</span></label>
              </div>
            </div>
            <div class="frow">
              <div class="fcol w200">
                <label> <span class="font14">年</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >龄：</span>
                </label> <label><span class="font14">{{ tableDetail.age }}</span></label>
              </div>
              <div class="fcol w400">
                <label>身份证号：</label>
                <label><span class="font14">{{ tableDetail.idNum }}</span></label>
              </div>
              <div class="fcol">
                <label>联系方式：</label> <label><span class="font14"> {{ tableDetail.contract }}</span></label>
              </div>
            </div>
            <div class="frow">
              <div class="fcol w200">
                <label>自理能力：</label>
                <label><span class="font14">{{ tableDetail.selfCareAbiyName }}</span></label>
              </div>
              <div class="fcol ">
                <label> <span class="font14">类</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >别：</span></label>
                <label><span class="font14">{{ tableDetail.personTypeNames }}</span></label>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">申请改造住宅<br>地址：</span></td>
        <td class="text-left" colspan="2">
            <span class="font14">{{ tableDetail.censusProvince }}{{ tableDetail.censusCity }}{{
                tableDetail.censusArea
              }}
              {{ tableDetail.censusStreet }}{{ tableDetail.censusCommunity }}
              {{ tableDetail.censusAddress }}
            </span></td>
        <td class="text-right"><span class="font14">服务机构</span></td>
        <td class="text-left">{{ tableDetail.workerCompName }}</td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">验收日期：</span></td>
        <td class="text-left" colspan="2">
          <span class="font14">{{ tableDetail.acceptDate }} </span></td>
        <td class="text-right"><span class="font14">验收结果：</span></td>
        <td class="text-left" :style="{'color':tableDetail.state === 1 || tableDetail.state === 2?'#13AD05':'#CC1D19'}">
          {{ tableDetail.state === 1 ? '合格' : (tableDetail.state === 0 ? '不合格' : '基本合格') }}
        </td>
      </tr>
      <tr v-if="tableDetail.typeList.length >0">
        <td colspan="5" style="border: 0;padding: 0">
          <table style="width: 100%">
            <tbody>
            <tr style="background-color: #F8F8F9">
              <td align="center">改造项目类别</td>
              <td align="center">改造项目</td>
              <td align="center">改造内容</td>
              <td align="center">改造数量</td>
              <td align="center">验收</td>
              <td align="center">备注</td>
            </tr>
            <tr v-for="(item,index) in tableDetail.typeList" :key="index">
              <td align="center">{{ item.reformProjectDetail.category }}</td>
              <td align="center">{{ item.reformProjectDetail.name }}</td>
              <td align="center">{{ item.reformProjectDetail.desc }}</td>
              <td align="center">{{ item.count }}</td>
              <td v-if="item.state === 1" align="center" style="width:120px;color: #13AD05">合格</td>
              <td v-else align="center" style="width:120px;color: #CC1D19">不合格</td>
              <td align="center">{{ item.acceptRemark }}</td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr v-if="tableDetail.productList.length >0">
        <td colspan="5" style="border: 0;padding: 0">
          <table style="width: 100%">
            <tbody>
            <tr style="background-color: #F8F8F9">
              <td align="center">适老化产品类别</td>
              <td align="center">图片</td>
              <td align="center">型号</td>
              <td align="center">名称</td>
              <td align="center">参数</td>
              <td align="center">功能</td>
              <td align="center">厂家</td>
              <td align="center">数量</td>
              <td align="center">验收</td>
              <td align="center">备注</td>
            </tr>
            <tr v-for="(item,index) in tableDetail.productList" :key="index">
              <td align="center" style="width:120px;">
                {{ item.reformProductDetail.categoryLevel1 }}{{ item.reformProductDetail.categoryLevel2 }}
              </td>
              <td align="center" style="width:120px;">
                <image-show :image-list="item.reformProductDetail.pictureInfoList"/>
              </td>
              <td align="center" style="width:120px;">{{ item.reformProductDetail.name }}</td>
              <td align="center" style="width:120px;">{{ item.reformProductDetail.model }}</td>
              <td align="center">{{ item.reformProductDetail.parameter }}</td>
              <td align="center">{{ item.reformProductDetail.func }}</td>
              <td align="center" style="width:60px;">{{ item.reformProductDetail.manufacturer }}</td>
              <td align="center" style="width:60px;">{{ item.count }}</td>
              <td v-if="item.state === 1" align="center" style="width:80px;color: #13AD05">合格</td>
              <td v-else align="center" style="width:80px;color: #CC1D19">不合格</td>
              <td align="center" style="width:120px;">{{ item.acceptRemark }}</td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">施工前照片</span></td>
        <td class="text-left" colspan="4">
          <div class="userimg float-left">
            <image-show :image-list="tableDetail.beforeConstructImgList"/>
          </div>
        </td>
      </tr>
<!--      <tr>-->
<!--        <td class="text-right"><span class="font14">施工中照片</span></td>-->
<!--        <td class="text-left" colspan="4">-->
<!--          <div class="userimg float-left">-->
<!--            <image-show :image-list="tableDetail.inConstructImgList"/>-->
<!--          </div>-->
<!--        </td>-->
<!--      </tr>-->
      <tr>
        <td class="text-right"><span class="font14">施工后照片</span></td>
        <td class="text-left" colspan="4">
          <div class="userimg float-left">
            <image-show :image-list="tableDetail.afterConstructImgList"/>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">适老化改造验<br>收照片:</span></td>
        <td class="text-left" colspan="4">
          <div class="userimg float-left">
            <image-show :image-list="tableDetail.imgList"/>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">适老化改造不<br>合格照片:</span></td>
        <td class="text-left" colspan="4">
          <div class="userimg float-left">
            <image-show :image-list="tableDetail.unqualifiedImgList"/>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">适老化改造验<br>收表:</span></td>
        <td class="text-left" colspan="4">
          <div class="userimg float-left">
            <image-show :image-list="tableDetail.acceptPaperList"/>
          </div>
        </td>
      </tr>
      <!--        <tr>-->
      <!--          <td class="text-right"><span class="font14">适老化改造验<br>收不合格处理<br>结果:</span></td>-->
      <!--          <td class="text-left" colspan="4">-->
      <!--            <div class="userimg float-left">-->
      <!--              <image-show :image-list="tableDetail.inConstructImgList" />-->
      <!--            </div>-->
      <!--          </td>-->
      <!--        </tr>-->
      <tr>
        <td class="text-right"><span class="font14">老人签名</span></td>
        <td class="text-left" colspan="4">
          <div class="userimg float-left">
            <image-show :image-list="tableDetail.signInfoList"/>
          </div>
          <div>本人及组织承诺对以上评估结果负责，愿意承担因评估不当产生的一切不良后果</div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">施工人员签字</span></td>
        <td class="text-left" colspan="4">
          <div class="userimg float-left">
            <image-show :image-list="tableDetail.workerSignInfoList"/>
          </div>
          <div>本人及组织承诺对以上评估结果负责，愿意承担因评估不当产生的一切不良后果</div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">施工人员</span></td>
        <td class="text-left" colspan="4">
          <span class="font14">{{ tableDetail.workerName }}</span></td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">备注：</span></td>
        <td class="text-left" colspan="2"><span class="font14">{{ tableDetail.remark }}</span></td>
      </tr>
      </tbody>
    </table>

    <!--放弃改造承诺书详情-->
    <table v-if="elderlyDataType === 'REFORM_GIVE_UP' && showDetail" class="table">
      <tbody>
      <tr>
        <td colspan="5" align="center" class="caption position-relation">
          放弃改造承诺书
          <div class="export-button" @click.stop="exportData(tableDetail.id,elderlyDataType,'放弃改造承诺书')">导出
          </div>
        </td>
      </tr>
      <tr>
        <td class="td1111">
          <div class="userimg float-left">
            <img
                width="107"
                height="135"
                :src="tableDetail.photoInfo.url"
                onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'"
            >
          </div>
        </td>
        <td colspan="4">
          <div class="tableinfo">
            <div class="frow">
              <div class="fcol w200">
                <label> <span class="font14">姓</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >名：</span>
                </label> <label><span class="font14"><a>{{ tableDetail.name }}</a></span></label>
              </div>
              <div class="fcol w400">
                <label> <span class="font14">性</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >别：</span>
                </label> <label><span class="font14">{{ tableDetail.sex }}</span></label>
              </div>
              <div class="fcol">
                <label>户籍所在地：</label> <label><span class="font14">
                    {{ tableDetail.censusProvince }}{{ tableDetail.censusCity }}{{ tableDetail.censusArea }}
                    {{ tableDetail.censusStreet }}{{ tableDetail.censusCommunity }}
                    {{ tableDetail.censusAddress }}</span></label>
              </div>
            </div>
            <div class="frow">
              <div class="fcol w200">
                <label> <span class="font14">年</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >龄：</span>
                </label> <label><span class="font14">{{ tableDetail.age }}</span></label>
              </div>
              <div class="fcol w400">
                <label>身份证号：</label>
                <label><span class="font14">{{ tableDetail.idNum }}</span></label>
              </div>
              <div class="fcol">
                <label>联系方式：</label> <label><span class="font14"> {{ tableDetail.contract }}</span></label>
              </div>
            </div>
            <div class="frow">

              <div class="fcol w200">
                <label>自理能力：</label>
                <label><span class="font14">{{ tableDetail.selfCareAbiyName }}</span></label>
              </div>
              <div class="fcol ">
                <label> <span class="font14">类</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    class="font14"
                >别：</span></label>
                <label><span class="font14">{{ tableDetail.personTypeNames }}</span></label>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">申请改造住宅<br>地址：</span></td>
        <td class="text-left" colspan="4">
            <span class="font14">{{ tableDetail.censusProvince }}{{ tableDetail.censusCity }}{{
                tableDetail.censusArea
              }}
              {{ tableDetail.censusStreet }}{{ tableDetail.censusCommunity }}
              {{ tableDetail.censusAddress }}
            </span></td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">服务机构：</span></td>
        <td class="text-left" colspan="2"><span class="font14">{{ tableDetail.workerCompName }}</span></td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">自愿放弃承诺<br>书-老人联:</span></td>
        <td class="text-left" colspan="4">
          <div class="userimg float-left">
            <image-show :image-list="tableDetail.oldPersonPaperList"/>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">自愿放弃承诺<br>书-民政联:</span></td>
        <td class="text-left" colspan="4">
          <div class="userimg float-left">
            <image-show :image-list="tableDetail.caPaperList"/>
          </div>
        </td>
      </tr>
      </tbody>
    </table>

    <div v-if="showDetail" class="m-footer">
      <el-button icon="fa fa-undo" @click="showDetail= false">返回</el-button>
    </div>

    <!--适老化改造申请表单-->
    <div v-if="elderlyDataType === 'APPLY' && showForm">
      <div v-if="applyForm.id === ''" class="title">添加适老化改造申请</div>
      <div v-else class="title">编辑适老化改造申请</div>
      <el-form ref="applyForm" :model="applyForm" :rules="applyFormRules" label-width="110px">
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="服务对象" prop="name">
              <el-input v-model="applyForm.name" placeholder="请输入服务对象" auto-complete="off"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="性别" prop="sex">
              <el-select v-model="applyForm.sex" placeholder="请选择状态" style="width: 100%">
                <el-option key="男" label="男" value="男"/>
                <el-option key="女" label="女" value="女"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="户籍所在地" prop="censusAddress">
              <el-input v-model="applyForm.censusAddress" placeholder="请输入户籍所在地" auto-complete="off"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="年龄" prop="age">
              <el-input v-model="applyForm.age" placeholder="请输入年龄" auto-complete="off"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="身份证号" prop="idNum">
              <el-input v-model="applyForm.idNum" placeholder="请输入身份证号" auto-complete="off"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系方式" prop="contract">
              <el-input v-model="applyForm.contract" placeholder="请输入联系方式" auto-complete="off"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="人员类别" prop="personTypeArr">
              <el-select v-model="applyForm.personTypeArr" multiple placeholder="请选择人员类别" style="width: 100%">
                <el-option
                    v-for="item in personCategoryList"
                    :key="item.dictPidVal"
                    :label="item.dictDesc"
                    :value="item.dictPidVal"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="自理能力" prop="selfCareAbiy">
              <el-select v-model="applyForm.selfCareAbiy" placeholder="请选择自理能力" style="width: 100%">
                <el-option
                    v-for="item in selfCareAbiyList"
                    :key="item.dictPidVal"
                    :label="item.dictDesc"
                    :value="item.dictPidVal"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="住宅类型" prop="houseType">
              <el-select v-model="applyForm.houseType" placeholder="请选择住宅类型" style="width: 100%">
                <el-option
                    v-for="item in houseTypeList"
                    :key="item.dictPidVal"
                    :label="item.dictDesc"
                    :value="item.dictPidVal"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="住宅情况" prop="houseBelongOwn">
              <el-select v-model="applyForm.houseBelongOwn" placeholder="请选择住宅情况" style="width: 100%">
                <el-option key="1" label="自有" :value="1"/>
                <el-option key="0" label="非自有" :value="0"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联络人姓名" prop="emergencyContactName">
              <el-input v-model="applyForm.emergencyContactName" placeholder="请输入联络人姓名" auto-complete="off"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联络人关系" prop="emergencyContactRelateShip">
              <el-input
                  v-model="applyForm.emergencyContactRelateShip"
                  placeholder="请输入联络人关系"
                  auto-complete="off"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="联络人电话" prop="emergencyContactTel">
              <el-input v-model="applyForm.emergencyContactTel" placeholder="请输入联络人电话" auto-complete="off"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="15">
            <el-form-item label="常住地址" prop="tenant">
              <region-form ref="regionForm" v-model="applyForm.tenant"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="" prop="usualAddress">
              <el-input v-model="applyForm.usualAddress" placeholder="请输入详细地址" auto-complete="off"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="房屋改造照片" prop="imgInfoList" :label-width="formLabelWidth">
              <upload-img :limit="6" :upload-lists="applyForm.imgInfoList" @uploadChange="applyFormImg"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="证明材料" prop="materialFileInfoList" :label-width="formLabelWidth">
              <upload-img
                  :limit="6"
                  :upload-lists="applyForm.materialFileInfoList"
                  @uploadChange="applyFormMaterialFiles"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item style="text-align: center" label-width="0">
              <el-button type="primary" icon="fa fa-check" @click="submitApplyForm('applyForm')">保存</el-button>
              <el-button icon="fa fa-undo" @click="showForm = false">返回</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!--放弃承诺书表单-->
    <div v-if="elderlyDataType === 'REFORM_GIVE_UP' && showForm">
      <div v-if="giveUpForm.id === ''" class="title">添加放弃改造承诺书</div>
      <div v-else class="title">编辑放弃改造承诺书</div>
      <el-form ref="giveUpForm" :model="giveUpForm" :rules="giveUpFormRule" label-width="110px">
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="服务对象" prop="name">
              <el-input v-model="giveUpForm.name" placeholder="请输入服务对象" auto-complete="off"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="性别" prop="sex">
              <el-select v-model="giveUpForm.sex" placeholder="请选择状态" style="width: 100%">
                <el-option key="男" label="男" value="男"/>
                <el-option key="女" label="女" value="女"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="户籍所在地" prop="censusAddress">
              <el-input v-model="giveUpForm.censusAddress" placeholder="请输入户籍所在地" auto-complete="off"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="年龄" prop="age">
              <el-input v-model="giveUpForm.age" placeholder="请输入年龄" auto-complete="off"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="身份证号" prop="idNum">
              <el-input v-model="giveUpForm.idNum" placeholder="请输入身份证号" auto-complete="off"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系方式" prop="contract">
              <el-input v-model="giveUpForm.contract" placeholder="请输入联系方式" auto-complete="off"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="人员类别" prop="personTypeArr">
              <el-select v-model="giveUpForm.personTypeArr" multiple placeholder="请选择人员类别" style="width: 100%">
                <el-option
                    v-for="item in personCategoryList"
                    :key="item.dictPidVal"
                    :label="item.dictDesc"
                    :value="item.dictPidVal"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="自理能力" prop="selfCareAbiy">
              <el-select v-model="giveUpForm.selfCareAbiy" placeholder="请选择自理能力" style="width: 100%">
                <el-option
                    v-for="item in selfCareAbiyList"
                    :key="item.dictPidVal"
                    :label="item.dictDesc"
                    :value="item.dictPidVal"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="服务机构" prop="houseType">
              <el-select v-model="giveUpForm.workerCompId" style="width: 100%" placeholder="请选择服务机构">
                <el-option
                    v-for="item in orgDatas"
                    :key="item.id"
                    :label="item.compName"
                    :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="15">
            <el-form-item label="常住地址" prop="tenant">
              <region-form ref="regionForm" v-model="giveUpForm.tenant"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="" prop="usualAddress">
              <el-input v-model="giveUpForm.usualAddress" placeholder="请输入详细地址" auto-complete="off"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="自愿放弃承诺 书-老人联" prop="imgInfoList" :label-width="formLabelWidth">
              <upload-img
                  :limit="6"
                  :upload-lists="giveUpForm.oldPersonPaperList"
                  @uploadChange="giveUpFormOldPersonPaper"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="自愿放弃承诺 书-民政联" prop="materialFileInfoList" :label-width="formLabelWidth">
              <upload-img
                  :limit="6"
                  :upload-lists="giveUpForm.caPaperList"
                  @uploadChange="giveUpFormCaPapers"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item style="text-align: center" label-width="0">
              <el-button type="primary" icon="fa fa-check" @click="submitGiveUp('giveUpForm')">保存</el-button>
              <el-button icon="fa fa-undo" @click="showForm = false">返回</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!--老化改造需求评估表单-->
    <div v-if="elderlyDataType === 'REQUIRE_CONFIRM' && showForm">
      <div v-if="requireForm.id === ''" class="title">添加适老化改造需求评估确认</div>
      <div v-else class="title">修改适老化改造需求评估确认</div>
      <el-form ref="requireForm" :model="requireForm" :rules="requireFormRule" label-width="110px">
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="服务对象" prop="name">
              <el-input v-model="requireForm.name" placeholder="请输入服务对象" auto-complete="off"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="性别" prop="sex">
              <el-select v-model="requireForm.sex" placeholder="请选择状态" style="width: 100%">
                <el-option key="男" label="男" value="男"/>
                <el-option key="女" label="女" value="女"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="户籍所在地" prop="censusAddress">
              <el-input v-model="requireForm.censusAddress" placeholder="请输入户籍所在地" auto-complete="off"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="年龄" prop="age">
              <el-input v-model="requireForm.age" placeholder="请输入年龄" auto-complete="off"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="身份证号" prop="idNum">
              <el-input v-model="requireForm.idNum" placeholder="请输入身份证号" auto-complete="off"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系方式" prop="contract">
              <el-input v-model="requireForm.contract" placeholder="请输入联系方式" auto-complete="off"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="人员类别" prop="personTypeArr">
              <el-select v-model="requireForm.personTypeArr" multiple placeholder="请选择人员类别" style="width: 100%">
                <el-option
                    v-for="item in personCategoryList"
                    :key="item.dictPidVal"
                    :label="item.dictDesc"
                    :value="item.dictPidVal"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="自理能力" prop="selfCareAbiy">
              <el-select v-model="requireForm.selfCareAbiy" placeholder="请选择自理能力" style="width: 100%">
                <el-option
                    v-for="item in selfCareAbiyList"
                    :key="item.dictPidVal"
                    :label="item.dictDesc"
                    :value="item.dictPidVal"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="服务机构" prop="houseType">
              <el-select v-model="requireForm.workerCompId" style="width: 100%" placeholder="请选择服务机构">
                <el-option
                    v-for="item in orgDatas"
                    :key="item.id"
                    :label="item.compName"
                    :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="15">
            <el-form-item label="常住地址" prop="tenant">
              <region-form ref="regionForm" v-model="requireForm.tenant"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="" prop="usualAddress">
              <el-input v-model="requireForm.usualAddress" placeholder="请输入详细地址" auto-complete="off"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="24">
            <table class="table" style="border-collapse:separate;">
              <tr>
                <td
                    colspan="2"
                    style="border-collapse:separate;border-radius: 10px 10px 0 0;text-align: right;padding: 15px 0;"
                >
                  <el-button type="primary" @click="addRequireProject">添加改造项目</el-button>
                  <el-button type="primary" style="margin:0 20px;" @click="addRequireProduct">添加适老化产品</el-button>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <table v-if="requireForm.typeList.length > 0" style="width: 100%">
                    <tbody>
                    <tr style="background-color: #F8F8F9">
                      <td align="center">改造项目类别</td>
                      <td align="center">改造项目</td>
                      <td align="center">改造内容</td>
                      <td align="center">改造数量</td>
                      <td align="center">费用/元</td>
                      <td align="center">操作</td>
                    </tr>
                    <tr v-for="(item,index) in requireForm.typeList" :key="index">
                      <td align="center">{{ item.reformProjectDetail.category }}</td>
                      <td align="center">{{ item.reformProjectDetail.name }}</td>
                      <td align="center">{{ item.reformProjectDetail.desc }}</td>
                      <td align="center">
                        <div class="number-handle">
                          <div class="number" @click="projectListNum(index,1)">
                            <img src="@/assets/images/require_num_reduce.png">
                          </div>
                          <div class="number">{{ item.count }}</div>
                          <div class="number" @click="projectListNum(index,2)">
                            <img src="@/assets/images/require_num_add.png">
                          </div>
                        </div>
                      </td>
                      <td align="center">{{ item.total }}</td>
                      <td align="center" style="color: #CC1D19" @click="deleteRequireType(index)">删除</td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <table v-if="requireForm.productList.length > 0" style="width: 100%">
                    <tbody>
                    <tr style="background-color: #F8F8F9">
                      <td align="center">适老化产品类别</td>
                      <td align="center">图片</td>
                      <td align="center">型号</td>
                      <td align="center">名称</td>
                      <td align="center">参数</td>
                      <td align="center">功能</td>
                      <td align="center">厂家</td>
                      <td align="center">数量</td>
                      <td align="center">费用</td>
                      <td align="center">操作</td>
                    </tr>
                    <tr v-for="(item,index) in requireForm.productList" :key="index">
                      <td align="center">
                        {{ item.reformProductDetail.categoryLevel1 }}{{ item.reformProductDetail.categoryLevel2 }}
                      </td>
                      <td align="center"><img :src="item.reformProductDetail.pictures" class="productPicture"></td>
                      <td align="center">{{ item.reformProductDetail.name }}</td>
                      <td align="center">{{ item.reformProductDetail.model }}</td>
                      <td align="center">{{ item.reformProductDetail.parameter }}</td>
                      <td align="center">{{ item.reformProductDetail.func }}</td>
                      <td align="center">{{ item.reformProductDetail.manufacturer }}</td>
                      <td align="center">
                        <div class="number-handle">
                          <div class="number" @click="productListNum(index,1)">
                            <img src="@/assets/images/require_num_reduce.png">
                          </div>
                          <div class="number">{{ item.count }}</div>
                          <div class="number" @click="productListNum(index,2)">
                            <img src="@/assets/images/require_num_add.png">
                          </div>
                        </div>
                      </td>
                      <td align="center">{{ item.total }}</td>
                      <td align="center" style="color: #CC1D19">删除</td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr style="background-color: #F8F8F9">
                <td style="width:152px;text-align: center;">增项费用</td>
                <td class="text-left">
                  <span class="font14">{{ requireForm.addListString }}</span></td>
              </tr>
              <tr v-if="requireForm.productList.length > 0 || requireForm.typeList.length>0||requireForm.addList.length>0">
                <td style="width:152px;text-align: center;border-collapse:separate;border-radius: 0 0 0 10px">改造合计费用
                </td>
                <td style="text-align: center;border-collapse:separate;border-radius: 0 0 10px 0">{{
                    requireForm.total
                  }}元
                </td>
              </tr>
            </table>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="需求评估确认" prop="confirmPaperList" :label-width="formLabelWidth">
              <upload-img
                  :limit="6"
                  :upload-lists="requireForm.confirmPaperList"
                  @uploadChange="requireFormConfirmPapers"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="评估人员签字" prop="imgInfoList" :label-width="formLabelWidth">
              <upload-img
                  :limit="6"
                  :upload-lists="requireForm.confirmPaperList"
                  @uploadChange="requireFormOldPersonPaper"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="" :label-width="formLabelWidth" style="margin-top: -30px">
              (本人及组织承诺对以上评估结果负责，愿意承担因评估不当产生的一切不良后果。)
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item style="text-align: center" label-width="0">
              <el-button type="primary" icon="fa fa-check" @click="submitRequire('requireForm')">保存</el-button>
              <el-button icon="fa fa-undo" @click="showForm = false">返回</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!--添加改造项目-->
    <el-dialog
        title="添加改造项目"
        width="400px"
        :visible.sync="dialogProjectVisible"
        :show-close="true"
        :close-on-click-modal="false"
    >
      <div v-show="!projectShowList" class="project-title-list">
        <div
            v-for="(item,index) in projectList"
            :key="index"
            class="project-title"
            :class="{'project-choose':index === projectChooseIndex}"
            @click="projectChooseIndex = index"
        >
          {{ item.name }}
        </div>
        <div v-show="!projectShowList" style="display: flex;align-items: center;justify-content:space-around">
          <el-button @click="dialogProjectVisible = false;">取消</el-button>
          <el-button type="primary" @click="projectShowList = true;addList=[]">确 定</el-button>
        </div>
      </div>

      <div v-if="projectShowList" class="project-content">
        <div class="project-content-title" @click="projectShowList = false">
          <img src="@/assets/images/require_return.png" class="require-return">
          <div>{{ projectList[projectChooseIndex].name }}</div>
        </div>
        <div
            v-for="(item,index) in projectList[projectChooseIndex].children"
            :key="index"
            class="product-content-content"
        >
          <img
              v-if="item.isCheck"
              src="@/assets/images/require_checked.png"
              class="require-check"
              @click="item.isCheck = false"
          >
          <img v-else src="@/assets/images/require_unchecked.png" class="require-check" @click="chooseProduct(index)">
          <div class="product-content-content-desc">
            <div>{{ item.name }}</div>
            <div class="product-desc-content">
              <div class="desc">{{ item.desc }}</div>
              <div v-show="item.isCheck" class="number-content">
                <div class="number-handle">
                  <div class="number" @click="projectNum(index,1)">
                    <img src="@/assets/images/require_num_reduce.png">
                  </div>
                  <div class="number">{{ item.number }}</div>
                  <div class="number" @click="projectNum(index,2)">
                    <img src="@/assets/images/require_num_add.png">
                  </div>
                </div>
                <div>¥{{ (item.price * item.number).toFixed(2) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="add-title">
          <div>增项费用</div>
          <img src="@/assets/images/require_add.png" class="require-add" @click="addRequireItemList">
        </div>
        <div v-for="(item,index) in addList" :key="'num'+index" class="add_list">
          <div class="add_money">
            <div>¥</div>
            <el-input v-model="item.total" type="number" min="0"/>
          </div>
          <div class="add_text">备注：</div>
          <el-input
              v-model="item.remark"
              class="add_remark"
              placeholder="请输入增项原因"
              placeholder-class="add_remark_placeholder"
          />
          <img
              src="@/assets/images/require_del.png"
              class="delete_item"
              @click.stop="deleteRequireItemList(index)"
          >
        </div>
        <div style="display: flex;align-items: center;justify-content:space-around;margin-top: 30px;">
          <el-button @click="dialogProjectVisible = false;">取消</el-button>
          <el-button type="primary" @click="confirmAddProject">确 定</el-button>
        </div>
      </div>
    </el-dialog>

    <!--添加产品-->
    <el-dialog
        title="添加适老化产品"
        width="400px"
        :visible.sync="dialogProductVisible"
        :show-close="true"
        :close-on-click-modal="false"
    >
      <div v-show="!productShowList" class="project-title-list">
        <div
            v-for="(item,index) in productList"
            :key="index"
            class="project-title"
            :class="{'project-choose':index === productChooseIndex}"
            @click="productChooseIndex = index"
        >
          {{ item.name }}
        </div>
        <div v-show="!productShowList" style="display: flex;align-items: center;justify-content:space-around">
          <el-button @click="dialogProductVisible = false;">取消</el-button>
          <el-button type="primary" @click="productShowList = true;addList=[]">确 定</el-button>
        </div>
      </div>

      <div v-if="productShowList" class="project-content">
        <div class="project-content-title" @click="productShowList = false">
          <img src="@/assets/images/require_return.png" class="require-return">
          <div>{{ productList[productChooseIndex].name }}</div>
        </div>
        <div style="display: flex;align-items: center;width: 362px">
          <div class="product-left">
            <div
                v-for="(item,index) in productList[productChooseIndex].category"
                :key="index"
                :class="{'product-left-choose':index === productCategoryChooseIndex}"
                @click="productCategoryChooseIndex = index"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="product-right">
            <div
                v-for="(product,productIndex) in productList[productChooseIndex].category[productCategoryChooseIndex].children"
                :key="productIndex"
                class="product-product-item"
            >
              <div class="flex-align-start">
                <img
                    v-if="product.isCheck"
                    src="@/assets/images/require_checked.png"
                    class="require-check"
                    @click="product.isCheck = false"
                >
                <img
                    v-else
                    src="@/assets/images/require_unchecked.png"
                    class="require-check"
                    @click="chooseProduct2(productIndex)"
                >
                <div class="prouct-item-content">
                  <div class="">{{ product.name }}</div>
                  <div class="flex-align-start top-10 color-66">
                    <div>型号：</div>
                    <div class="flex-1">{{ product.model }}</div>
                  </div>
                  <div class="flex-align-start top-12 color-66">
                    <div>参数：</div>
                    <div class="flex-1">{{ product.parameter }}</div>
                  </div>
                  <div class="flex-align-start top-12 color-66">
                    <div>功能：</div>
                    <div class="flex-1">{{ product.func }}</div>
                  </div>
                  <div class="flex-align-start top-12 color-66">
                    <div>厂家：</div>
                    <div class="flex-1">{{ product.manufacturer }}</div>
                  </div>
                  <div class="flex-align-start top-12 color-66">
                    <div>单价：</div>
                    <div class="flex-1">¥{{ product.price }}</div>
                  </div>
                </div>
              </div>
              <div v-show="product.isCheck" class="product-number">
                <div>合计：¥{{ (parseFloat(product.number) * parseFloat(product.price)).toFixed(2) }}
                </div>
                <div class="number-handle" style="margin-left: 16px">
                  <div class="number" @click="productNum(productIndex,1)">
                    <img src="@/assets/images/require_num_reduce.png">
                  </div>
                  <div class="number">{{ product.number }}</div>
                  <div class="number" @click="productNum(productIndex,2)">
                    <img src="@/assets/images/require_num_add.png">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="add-title">
          <div>增项费用</div>
          <img src="@/assets/images/require_add.png" class="require-add" @click="addRequireItemList">
        </div>
        <div v-for="(item,index) in addList" :key="'num'+index" class="add_list">
          <div class="add_money">
            <div>¥</div>
            <el-input v-model="item.total" type="number" min="0"/>
          </div>
          <div class="add_text">备注：</div>
          <el-input
              v-model="item.remark"
              class="add_remark"
              placeholder="请输入增项原因"
              placeholder-class="add_remark_placeholder"
          />
          <img
              src="@/assets/images/require_del.png"
              class="delete_item"
              @click.stop="deleteRequireItemList(index)"
          >
        </div>
        <div style="display: flex;align-items: center;justify-content:space-around;margin-top: 30px;">
          <el-button @click="dialogproductVisible = false;">取消</el-button>
          <el-button type="primary" @click="confirmAddProduct">确 定</el-button>
        </div>
      </div>
    </el-dialog>
    <!--    </div>-->
    <!--    <el-dialog title="添加适老化改造" :visible.sync="dialogFormVisible" :show-close="false" :close-on-click-modal="false">-->
    <!--      <el-form :model="form" label-position="left">-->
    <!--        <el-form-item label="服务机构" :label-width="formLabelWidth">-->
    <!--          <el-select v-model="form.reformOrgId" placeholder="请选择">-->
    <!--            <el-option-->
    <!--              v-for="item in orgDatas"-->
    <!--              :key="item.id"-->
    <!--              :label="item.compName"-->
    <!--              :value="item.id"-->
    <!--            />-->
    <!--          </el-select>-->
    <!--        </el-form-item>-->
    <!--        &lt;!&ndash; <el-form-item label="类型" :label-width="formLabelWidth">-->
    <!--          <el-input v-model="form.name" autocomplete="off"></el-input>-->
    <!--        </el-form-item> &ndash;&gt;-->
    <!--        <el-form-item label="服务方案" :label-width="formLabelWidth">-->
    <!--          <el-input v-model="form.plan" autocomplete="off" />-->
    <!--        </el-form-item>-->
    <!--        <el-form-item label="评估员姓名" :label-width="formLabelWidth">-->
    <!--          <el-input v-model="form.assessorName" autocomplete="off" />-->
    <!--        </el-form-item>-->
    <!--        <el-form-item label="初步评估结果" :label-width="formLabelWidth">-->
    <!--          <el-input v-model="form.preEvalResult" autocomplete="off" />-->
    <!--        </el-form-item>-->
    <!--        <el-form-item label="最终评估结果" :label-width="formLabelWidth">-->
    <!--          <el-input v-model="form.finalEvalResult" autocomplete="off" />-->
    <!--        </el-form-item>-->
    <!--        <el-form-item label="审核意见" :label-width="formLabelWidth">-->
    <!--          <el-input v-model="form.reviewComments" autocomplete="off" />-->
    <!--        </el-form-item>-->
    <!--        <el-form-item label="专家意见" :label-width="formLabelWidth">-->
    <!--          <el-input v-model="form.expertComments" autocomplete="off" />-->
    <!--        </el-form-item>-->
    <!--        <el-form-item label="适老化产品明细" :label-width="formLabelWidth">-->
    <!--          <el-input v-model="form.productDetail" autocomplete="off" />-->
    <!--        </el-form-item>-->
    <!--        <el-form-item label="状态" :label-width="formLabelWidth">-->
    <!--          <el-select v-model="form.status" placeholder="请选择状态" style="width:100%">-->
    <!--            <el-option-->
    <!--              label="合格"-->
    <!--              value="合格"-->
    <!--            />-->
    <!--            <el-option-->
    <!--              label="待验收"-->
    <!--              value="待验收"-->
    <!--            />-->
    <!--          </el-select>-->
    <!--        </el-form-item>-->
    <!--        <el-form-item label="改造前照片" :label-width="formLabelWidth">-->
    <!--          <upload-img :limit="6" :upload-lists="beforeImgsList" @uploadChange="onBeforeImg" />-->
    <!--        </el-form-item>-->
    <!--        <el-form-item label="改造后照片" :label-width="formLabelWidth">-->
    <!--          <upload-img :limit="6" :upload-lists="afterImgsList" @uploadChange="onAfterImg" />-->
    <!--        </el-form-item>-->
    <!--        <el-form-item label="评估表，审批表，前后对比档案" :label-width="formLabelWidth">-->
    <!--          <upload-img :limit="6" :upload-lists="otherImgsList" @uploadChange="onOtherImg" />-->
    <!--        </el-form-item>-->
    <!--      </el-form>-->
    <!--      <div slot="footer" class="dialog-footer">-->
    <!--        <el-button @click="()=>{dialogFormVisible = false,form={}}">取 消</el-button>-->
    <!--        <el-button type="primary" @click="addElderBtn()">确 定</el-button>-->
    <!--      </div>-->
    <!--    </el-dialog>-->
  </div>

</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import UploadImg from '@/components/UploadImg.vue'
import imageShow from '@/components/imageShow'
import RegionForm from '@/components/RegionForm.vue'
import api from '@/store/API/api'

export default {
  components: {
    UploadImg,
    RegionForm,
    imageShow
  },
  data() {
    return {
      size: 'small ',
      // oldPersonName:'aaa', //老人姓名
      table: {},
      isshow: '',
      dialogFormVisible: false,
      form: {
        oldPersonId: this.$route.params.id
      },
      formLabelWidth: '120px',
      beforeImgsList: [],
      afterImgsList: [],
      otherImgsList: [],
      orgDatas: [],
      isedit: false,
      isplus: false,
      id: '',
      // 分页的参数配置
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      tabIndex: 2,
      tabList: [
        {
          id: 0,
          name: '适老化改造申请',
          field: 'selectApply',
          showAdd: true,
          elderlyDataType: 'APPLY',
          tableColumn: [
            {
              type: 'seq',
              title: '序号',
              width: 60,
              align: 'center'
            },
            {
              field: 'oldPersonName',
              title: '服务对象',
              showOverflow: true,
              align: 'center',
              minWidth: '100',
              slots: {
                default: 'personName'
              }
            },
            {
              field: 'oldPersonSex',
              title: '性别',
              showOverflow: true,
              align: 'center',
              minWidth: '80'
            },
            {
              field: 'oldAge',
              title: '年龄',
              showOverflow: true,
              align: 'center',
              minWidth: '80'
            },
            {
              field: 'oldPersonIdNum',
              title: '身份证号',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              field: 'oldPersonContract',
              title: '联系方式',
              showOverflow: true,
              align: 'center',
              minWidth: '160'
            },
            {
              field: 'oldPersonTypeNames',
              title: '类别',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              field: 'oldPersonAddress',
              title: '申请改造住宅地址',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              field: 'elderlyDataType',
              title: '服务项目',
              showOverflow: true,
              align: 'center',
              minWidth: '200',
              slots: {
                default: 'elderlyDataType'
              }
            },
            {
              title: '操作',
              minWidth: '140',
              showOverflow: true,
              align: 'center',
              slots: {
                default: 'operation'
              }
            }
          ]
        },
        {
          id: 1,
          name: '适老化改造信息确认',
          field: 'selectBasicInfo',
          showAdd: false,
          elderlyDataType: 'REQUIRE_ACCEPT',
          tableColumn: [
            {
              type: 'seq',
              title: '序号',
              width: 60,
              align: 'center'
            },
            {
              field: 'oldPersonName',
              title: '服务对象',
              showOverflow: true,
              align: 'center',
              minWidth: '100',
              slots: {
                default: 'personName'
              }
            },
            {
              field: 'oldPersonSex',
              title: '性别',
              showOverflow: true,
              align: 'center',
              minWidth: '80'
            },
            {
              field: 'oldAge',
              title: '年龄',
              showOverflow: true,
              align: 'center',
              minWidth: '80'
            },
            {
              field: 'oldPersonIdNum',
              title: '身份证号',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              field: 'oldPersonContract',
              title: '联系方式',
              showOverflow: true,
              align: 'center',
              minWidth: '160'
            },
            {
              field: 'oldPersonTypeNames',
              title: '类别',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              field: 'oldPersonAddress',
              title: '申请改造住宅地址',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              field: 'elderlyDataType',
              title: '服务项目',
              showOverflow: true,
              align: 'center',
              minWidth: '200',
              slots: {
                default: 'elderlyDataType'
              }
            },
            {
              title: '操作',
              minWidth: '140',
              showOverflow: true,
              align: 'center',
              slots: {
                default: 'operation'
              }
            }]
        },
        {
          id: 2,
          name: '适老化改造评估',
          field: 'selectPersonAssess',
          showAdd: false,
          elderlyDataType: 'REQUIRE_ACCEPT',
          tableColumn: [
            {
              type: 'seq',
              title: '序号',
              width: 60,
              align: 'center'
            },
            {
              field: 'oldPersonName',
              title: '服务对象',
              showOverflow: true,
              align: 'center',
              minWidth: '100',
              slots: {
                default: 'personName'
              }
            },
            {
              field: 'oldPersonSex',
              title: '性别',
              showOverflow: true,
              align: 'center',
              minWidth: '80'
            },
            {
              field: 'oldAge',
              title: '年龄',
              showOverflow: true,
              align: 'center',
              minWidth: '80'
            },
            {
              field: 'oldPersonIdNum',
              title: '身份证号',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              field: 'oldPersonContract',
              title: '联系方式',
              showOverflow: true,
              align: 'center',
              minWidth: '160'
            },
            {
              field: 'oldPersonTypeNames',
              title: '类别',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              field: 'oldPersonAddress',
              title: '申请改造住宅地址',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              field: 'elderlyDataType',
              title: '服务项目',
              showOverflow: true,
              align: 'center',
              minWidth: '200',
              slots: {
                default: 'elderlyDataType'
              }
            },
            {
              field: 'workerName',
              title: '评估人员',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              title: '操作',
              minWidth: '140',
              showOverflow: true,
              align: 'center',
              slots: {
                default: 'operation'
              }
            }
          ]
        },
        {
          id: 3,
          name: '适老化改造需求评估确认',
          field: 'selectRequireConfirm',
          showAdd: true,
          elderlyDataType: 'REQUIRE_CONFIRM',
          tableColumn: [
            {
              type: 'seq',
              title: '序号',
              width: 60,
              align: 'center'
            },
            {
              field: 'oldPersonName',
              title: '服务对象',
              showOverflow: true,
              align: 'center',
              minWidth: '100',
              slots: {
                default: 'personName'
              }
            },
            {
              field: 'oldPersonSex',
              title: '性别',
              showOverflow: true,
              align: 'center',
              minWidth: '80'
            },
            {
              field: 'oldAge',
              title: '年龄',
              showOverflow: true,
              align: 'center',
              minWidth: '80'
            },
            {
              field: 'oldPersonIdNum',
              title: '身份证号',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              field: 'oldPersonContract',
              title: '联系方式',
              showOverflow: true,
              align: 'center',
              minWidth: '160'
            },
            {
              field: 'oldPersonTypeNames',
              title: '类别',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              field: 'oldPersonAddress',
              title: '申请改造住宅地址',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              field: 'elderlyDataType',
              title: '服务项目',
              showOverflow: true,
              align: 'center',
              minWidth: '200',
              slots: {
                default: 'elderlyDataType'
              }
            },
            {
              title: '操作',
              minWidth: '140',
              showOverflow: true,
              align: 'center',
              slots: {
                default: 'operation'
              }
            }
          ]
        },
        {
          id: 4,
          name: '适老化改造施工',
          field: 'selectRequireConstruct',
          showAdd: false,
          elderlyDataType: 'REQUIRE_ACCEPT',
          tableColumn: [
            {
              type: 'seq',
              title: '序号',
              width: 60,
              align: 'center'
            },
            {
              field: 'oldPersonName',
              title: '服务对象',
              showOverflow: true,
              align: 'center',
              minWidth: '100',
              slots: {
                default: 'personName'
              }
            },
            {
              field: 'oldPersonSex',
              title: '性别',
              showOverflow: true,
              align: 'center',
              minWidth: '80'
            },
            {
              field: 'oldAge',
              title: '年龄',
              showOverflow: true,
              align: 'center',
              minWidth: '80'
            },
            {
              field: 'oldPersonIdNum',
              title: '身份证号',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              field: 'oldPersonContract',
              title: '联系方式',
              showOverflow: true,
              align: 'center',
              minWidth: '160'
            },
            {
              field: 'oldPersonTypeNames',
              title: '类别',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              field: 'oldPersonAddress',
              title: '申请改造住宅地址',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              field: 'elderlyDataType',
              title: '服务项目',
              showOverflow: true,
              align: 'center',
              minWidth: '200',
              slots: {
                default: 'elderlyDataType'
              }
            },
            {
              title: '操作',
              minWidth: '140',
              showOverflow: true,
              align: 'center',
              slots: {
                default: 'operation'
              }
            }
          ]
        },
        {
          id: 5,
          name: '适老化改造验收',
          field: 'selectRequireAccept',
          showAdd: false,
          elderlyDataType: 'REQUIRE_ACCEPT',
          tableColumn: [
            {
              type: 'seq',
              title: '序号',
              width: 60,
              align: 'center'
            },
            {
              field: 'oldPersonName',
              title: '服务对象',
              showOverflow: true,
              align: 'center',
              minWidth: '100',
              slots: {
                default: 'personName'
              }
            },
            {
              field: 'oldPersonSex',
              title: '性别',
              showOverflow: true,
              align: 'center',
              minWidth: '80'
            },
            {
              field: 'oldAge',
              title: '年龄',
              showOverflow: true,
              align: 'center',
              minWidth: '80'
            },
            {
              field: 'oldPersonIdNum',
              title: '身份证号',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              field: 'oldPersonContract',
              title: '联系方式',
              showOverflow: true,
              align: 'center',
              minWidth: '160'
            },
            {
              field: 'oldPersonTypeNames',
              title: '类别',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              field: 'oldPersonAddress',
              title: '申请改造住宅地址',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              field: 'elderlyDataType',
              title: '服务项目',
              showOverflow: true,
              align: 'center',
              minWidth: '200',
              slots: {
                default: 'elderlyDataType'
              }
            },
            {
              field: 'workerName',
              title: '验收人员',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              title: '操作',
              minWidth: '140',
              showOverflow: true,
              align: 'center',
              slots: {
                default: 'operation'
              }
            }
          ]
        },
        {
          id: 6,
          name: '适老化改造信息汇总',
          field: 'selectAll',
          showAdd: false,
          elderlyDataType: 'REQUIRE_ACCEPT',
          tableColumn: [
            {
              type: 'seq',
              title: '序号',
              width: 60,
              align: 'center'
            },
            {
              field: 'oldPersonName',
              title: '服务对象',
              showOverflow: true,
              align: 'center',
              minWidth: '100',
              slots: {
                default: 'personName'
              }
            },
            {
              field: 'oldPersonSex',
              title: '性别',
              showOverflow: true,
              align: 'center',
              minWidth: '80'
            },
            {
              field: 'oldAge',
              title: '年龄',
              showOverflow: true,
              align: 'center',
              minWidth: '80'
            },
            {
              field: 'oldPersonIdNum',
              title: '身份证号',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              field: 'oldPersonContract',
              title: '联系方式',
              showOverflow: true,
              align: 'center',
              minWidth: '160'
            },
            {
              field: 'oldPersonTypeNames',
              title: '类别',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              field: 'oldPersonAddress',
              title: '申请改造住宅地址',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              field: 'elderlyDataType',
              title: '服务项目',
              showOverflow: true,
              align: 'center',
              minWidth: '200',
              slots: {
                default: 'elderlyDataType'
              }
            },
            {
              title: '操作',
              minWidth: '140',
              showOverflow: true,
              align: 'center',
              slots: {
                default: 'operation'
              }
            }
          ]
        },
        {
          id: 8,
          name: '放弃改造承诺书',
          field: 'selectReformGiveUp',
          showAdd: true,
          elderlyDataType: 'REFORM_GIVE_UP',
          tableColumn: [
            {
              type: 'seq',
              title: '序号',
              width: 60,
              align: 'center'
            },
            {
              field: 'oldPersonName',
              title: '服务对象',
              showOverflow: true,
              align: 'center',
              minWidth: '100',
              slots: {
                default: 'personName'
              }
            },
            {
              field: 'oldPersonSex',
              title: '性别',
              showOverflow: true,
              align: 'center',
              minWidth: '80'
            },
            {
              field: 'oldAge',
              title: '年龄',
              showOverflow: true,
              align: 'center',
              minWidth: '80'
            },
            {
              field: 'oldPersonIdNum',
              title: '身份证号',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              field: 'oldPersonContract',
              title: '联系方式',
              showOverflow: true,
              align: 'center',
              minWidth: '160'
            },
            {
              field: 'oldPersonTypeNames',
              title: '类别',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              field: 'oldPersonAddress',
              title: '申请改造住宅地址',
              showOverflow: true,
              align: 'center',
              minWidth: '200'
            },
            {
              field: 'elderlyDataType',
              title: '服务项目',
              showOverflow: true,
              align: 'center',
              minWidth: '200',
              slots: {
                default: 'elderlyDataType'
              }
            },
            {
              title: '操作',
              minWidth: '140',
              showOverflow: true,
              align: 'center',
              slots: {
                default: 'operation'
              }
            }
          ]
        }
      ],
      tableColumn: [],
      tableData: [],
      loading: false,
      showDetail: false,
      showForm: false,
      elderlyDataType: '',
      tableDetail: {},
      personCategoryList: [],
      selfCareAbiyList: [],
      houseTypeList: [],
      userId: '',
      dialogProjectVisible: false,
      originalProjectList: [],
      projectList: [],
      projectChooseIndex: -1,
      projectShowList: false,
      dialogProductVisible: false,
      originalProductList: [],
      productList: [],
      productChooseIndex: -1,
      productShowList: false,
      productCategoryChooseIndex: 0,
      addList: [],
      applyForm: {
        id: '',
        censusAddress: '',
        age: '',
        contract: '',
        emergencyContactName: '',
        emergencyContactRelateShip: '',
        emergencyContactTel: '',
        fromPc: true,
        houseType: '',
        idNum: '',
        imgs: '',
        imgInfoList: [],
        materialFiles: '',
        materialFileInfoList: [],
        name: '',
        oldPersonId: '',
        personTypeArr: [],
        selfCareAbiy: '',
        sex: '',
        tenant: [],
        usualAddress: '',
        houseBelongOwn: ''
      },
      applyFormRules: {
        name: [{ required: true, message: '请输入服务人员名称', trigger: 'blur' }],
        sex: [{ required: true, message: '请输选择性别', trigger: 'blur' }],
        censusAddress: [{ required: true, message: '请输入户籍所在地', trigger: 'blur' }],
        age: [{ required: true, message: '请输入年龄', trigger: 'blur' }],
        idNum: [{ required: true, message: '请输入身份证号', trigger: 'blur' }],
        contract: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
        personTypeArr: [{ required: true, message: '请选择人员类别', trigger: 'blur' }],
        selfCareAbiy: [{ required: true, message: '请选择自理能力', trigger: 'blur' }],
        houseType: [{ required: true, message: '请选择住宅类型', trigger: 'blur' }],
        houseBelongOwn: [{ required: true, message: '请选择住宅情况', trigger: 'blur' }],
        emergencyContactName: [{ required: true, message: '请输入联络人姓名', trigger: 'blur' }],
        emergencyContactRelateShip: [{ required: true, message: '请输入联络人关系', trigger: 'blur' }],
        emergencyContactTel: [{ required: true, message: '请输入联络人电话', trigger: 'blur' }],
        imgInfoList: [{ required: true, message: '请上传房屋改造照片', trigger: 'blur' }],
        materialFileInfoList: [{ required: true, message: '请上传证明材料', trigger: 'blur' }]
      },
      giveUpForm: {
        id: '',
        name: '',
        sex: '',
        age: '',
        idNum: '',
        contract: '',
        personTypeArr: [],
        selfCareAbiy: '',
        tenant: [],
        censusAddress: '',
        workerCompId: '',
        oldPersonPapers: '',
        oldPersonPaperList: [],
        caPapers: '',
        caPaperList: []
      },
      giveUpFormRule: {
        name: [{ required: true, message: '请输入服务人员名称', trigger: 'blur' }],
        sex: [{ required: true, message: '请输选择性别', trigger: 'blur' }],
        censusAddress: [{ required: true, message: '请输入户籍所在地', trigger: 'blur' }],
        age: [{ required: true, message: '请输入年龄', trigger: 'blur' }],
        idNum: [{ required: true, message: '请输入身份证号', trigger: 'blur' }],
        contract: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
        personTypeArr: [{ required: true, message: '请选择人员类别', trigger: 'blur' }],
        selfCareAbiy: [{ required: true, message: '请选择自理能力', trigger: 'blur' }],
        workerCompId: [{ required: true, message: '请选择服务机构', trigger: 'blur' }]
      },
      requireForm: {
        id: '',
        name: '',
        sex: '',
        age: '',
        idNum: '',
        contract: '',
        personTypeArr: [],
        selfCareAbiy: '',
        tenant: [],
        censusAddress: '',
        productList: [],
        typeList: [],
        addList: [],
        addListString: '',
        confirmPapers: '',
        confirmPaperList: [],
        total: 0
      },
      requireFormRule: {
        name: [{ required: true, message: '请输入服务人员名称', trigger: 'blur' }],
        sex: [{ required: true, message: '请输选择性别', trigger: 'blur' }],
        censusAddress: [{ required: true, message: '请输入户籍所在地', trigger: 'blur' }],
        age: [{ required: true, message: '请输入年龄', trigger: 'blur' }],
        idNum: [{ required: true, message: '请输入身份证号', trigger: 'blur' }],
        contract: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
        personTypeArr: [{ required: true, message: '请选择人员类别', trigger: 'blur' }],
        selfCareAbiy: [{ required: true, message: '请选择自理能力', trigger: 'blur' }],
        workerCompId: [{ required: true, message: '请选择服务机构', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.queryList()
    this.querySumCenterList()
    this.getDicList()
    this.getLogUserInfo()
    this.queryProjectList()
    this.queryProductList()
  },
  computed: {
    ...mapState(['layouts']),
    ...mapGetters(['seqId'])
  },
  methods: {
    ...mapActions([
      'downloadFile',
      'elderReformDetai',
      'getServeOrgsListServe',
      'addElderReform',
      'updateElderReform',
      'deleteElderReform',
      'elderList',
      'acceptDetail',
      'applyDetail',
      'applyUpdate',
      'applyAdd',
      'applyDelete',
      'basicDetail',
      'personDetail',
      'requireDetail',
      'requireDelete',
      'requireAdd',
      'requireUpdate',
      'constructDetail',
      'giveUpDetail',
      'giveUpUpdate',
      'giveUpAdd',
      'giveUpDelete',
      'getSysDictServe',
      'getServeOrgsListServe',
      'loginUserInfo',
      'projectCategoryList',
      'getProjectList',
      'productCategoryList',
      'getProductList',
      'basicDelete',
      'constructDelete',
      'acceptDelete',
      'personDelete',
      'elderlyOrientedSummaryDelete'
    ]),
    exportData(id, type, fieldName) {
      let url = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_BASEURL
      url += api.elderlyOrientedDown
      const myObj = {
        method: 'post',
        url,
        fileName: fieldName,
        params: {
          elderlyDataType: type,
          id: id
        }
      }
      this.$tools.exportMethodWord(myObj)
    },
    getLogUserInfo() {
      this.loginUserInfo().then(res => {
        if (res.code === 200) {
          this.userId = res.data.userId
        }
      })
    },
    queryProjectList() {
      this.projectCategoryList().then(res => {
        if (res.code === 200) {
          const projectList = []
          res.data.forEach(item => [
            projectList.push({
              name: item,
              isShow: false,
              children: []
            })
          ])
          this.originalProjectList = projectList
          this.getProjectList({ pageSize: -1 }).then(res => {
            if (res.code === 200) {
              this.originalProjectList.forEach(item => {
                res.data.dataList.forEach(listItem => {
                  if (listItem.category === item.name) {
                    listItem.isCheck = false
                    listItem.number = 1
                    item.children.push(listItem)
                  }
                })
              })
              console.log(this.originalProjectList)
            }
          })
        }
      })
    },

    queryProductList() {
      this.productCategoryList().then(res => {
        if (res.code === 200) {
          this.getProductList({
            pageSize: -1
          }).then(productResult => {
            if (productResult.code === 200) {
              const productList = []
              for (const i in res.data.categoryLevel1N2Mapping) {
                const productCategoryOne = {
                  name: i,
                  isShow: false,
                  chooseCategoryTwo: 0,
                  category: []
                }
                const productCategoryOneCategory = []
                for (const j in res.data.categoryLevel1N2Mapping[i]) {
                  const productCategoryTwoList = {
                    name: res.data.categoryLevel1N2Mapping[i][j],
                    children: []
                  }
                  productResult.data.dataList.forEach(listItem => {
                    if (listItem.categoryLevel1 === i &&
                        listItem.categoryLevel2 === res.data
                            .categoryLevel1N2Mapping[i][j]) {
                      listItem.isCheck = false
                      listItem.number = 1
                      productCategoryTwoList.children.push(listItem)
                    }
                  })
                  productCategoryOneCategory.push(productCategoryTwoList)
                }
                productCategoryOne.category = productCategoryOneCategory
                productList.push(productCategoryOne)
              }
              console.log(productList)
              this.originalProductList = productList
            }
          })
        }
      })
    },
    getDicList() {
      this.getSysDictList('personCategory', 'personCategoryList') // 人员类别
      this.getSysDictList('selfCareAbiy', 'selfCareAbiyList') // 自理能力
      this.getSysDictList('houseType', 'houseTypeList') // 住宅类型
    },
    // 获取数据字典
    getSysDictList(code, codeList) {
      const params = {
        typeCode: code
      }
      this.getSysDictServe(params).then((res) => {
        if (res.code == 200) {
          this[codeList] = res.data
        }
      })
    },
    queryList() {
      this.loading = true
      this.tableColumn = this.tabList[this.tabIndex].tableColumn
      const data = {
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
        oldPersonId: this.$route.params.id,
        selectAll: false
      }
      data[this.tabList[this.tabIndex].field] = true
      this.elderList(data).then((res) => {
        if (res.code === 200) {
          res.data.dataList.forEach(item => {
            item.isMore = false
          })
          this.tableData = res.data.dataList
          this.tablePage.totalResult = +res.data.total
        }
        this.loading = false
      })
    },

    // 分页功能
    pageChange(item) {
      if (item.type === 'size') {
        this.tablePage.currentPage = 1
      } else {
        this.tablePage.currentPage = item.currentPage
      }
      this.queryList()
    },
    /**
     * 切换标签
     * @param index
     */
    changeTab(index) {
      this.tabIndex = index
      this.tablePage.currentPage = 1
      this.queryList()
    },

    /**
     * 添加项目
     */
    addRequireProject() {
      this.projectList = JSON.parse(JSON.stringify(this.originalProjectList))
      this.projectShowList = false
      this.projectChooseIndex = -1
      this.dialogProjectVisible = true
    },

    addRequireProduct() {
      this.productList = JSON.parse(JSON.stringify(this.originalProductList))
      this.productShowList = false
      this.productChooseIndex = -1
      this.productCategoryChooseIndex = 0
      this.dialogProductVisible = true
    },

    /**
     * 选中项目类型
     */
    chooseProduct(index) {
      const data = this.projectList[this.projectChooseIndex].children[index]
      let can = true
      this.requireForm.typeList.forEach(item => {
        if (item.reformProjectId === data.id) {
          can = false
        }
      })
      if (!can) {
        this.$message.warning(data.name + '已添加')
        return
      }
      this.projectList[this.projectChooseIndex].children[index].isCheck = !data.isCheck
    },

    chooseProduct2(index) {
      const data = this.productList[this.productChooseIndex].category[this.productCategoryChooseIndex].children[index]
      let can = true
      this.requireForm.productList.forEach(item => {
        if (item.reformProductId === data.id) {
          can = false
        }
      })
      if (!can) {
        this.$message.warning(data.name + '已添加')
        return
      }
      this.productList[this.productChooseIndex].category[this.productCategoryChooseIndex].children[index].isCheck = !data.isCheck
    },

    /**
     * 项目数量变化
     */
    projectNum(index, type) {
      if (type === 1) {
        if (this.projectList[this.projectChooseIndex].children[index].number > 1) {
          this.projectList[this.projectChooseIndex].children[index].number--
        }
      } else {
        this.projectList[this.projectChooseIndex].children[index].number++
      }
    },

    productNum(index, type) {
      if (type === 1) {
        if (this.productList[this.productChooseIndex].category[this.productCategoryChooseIndex].children[index].number > 1) {
          this.productList[this.productChooseIndex].category[this.productCategoryChooseIndex].children[index].number--
        }
      } else {
        this.productList[this.productChooseIndex].category[this.productCategoryChooseIndex].children[index].number++
      }
    },

    /**
     * 项目数量变化
     */
    projectListNum(index, type) {
      if (type === 1) {
        if (this.requireForm.typeList[index].count > 1) {
          this.requireForm.typeList[index].count--
        }
      } else {
        this.requireForm.typeList[index].count++
      }
      this.handleRequireTotal()
    },

    /**
     * 产品数量变化
     */
    productListNum(index, type) {
      if (type === 1) {
        if (this.requireForm.productList[index].count > 1) {
          this.requireForm.productList[index].count--
        }
      } else {
        this.requireForm.productList[index].count++
      }
      this.handleRequireTotal()
    },

    /**
     * 添加产品
     */
    confirmAddProject() {
      let isFalse = false
      this.addList.forEach(item => {
        if (item.total === '') {
          isFalse = true
        }
      })
      if (isFalse) {
        this.$message.warning('增项费用未填写')
      }

      this.addList.forEach(item => {
        this.requireForm.addList.push(item)
      })
      const addListString = []
      this.requireForm.addList.forEach(item => {
        addListString.push(item.remark + ' ' + item.total)
      })
      this.requireForm.addListString = addListString.join(';')
      this.projectList.forEach(item => {
        item.children.forEach(project => {
          if (project.isCheck) {
            this.requireForm.typeList.push({
              count: project.number,
              reformProductId: 0,
              reformProductDetail: null,
              reformProjectDetail: project,
              reformProjectId: project.id,
              remark: '',
              total: (project.number * project.price).toFixed(2),
              type: 'REFORM_PROJECT'
            })
          }
        })
      })
      this.dialogProjectVisible = false
      this.handleRequireTotal()
    },

    confirmAddProduct() {
      let isFalse = false
      this.addList.forEach(item => {
        if (item.total === '') {
          isFalse = true
        }
      })
      if (isFalse) {
        this.$message.warning('增项费用未填写')
      }

      this.addList.forEach(item => {
        this.requireForm.addList.push(item)
      })
      const addListString = []
      this.requireForm.addList.forEach(item => {
        addListString.push(item.remark + ' ' + item.total)
      })
      this.requireForm.addListString = addListString.join(';')
      this.productList.forEach(item => {
        item.category.forEach(category => {
          category.children.forEach(product => {
            if (product.isCheck) {
              this.requireForm.productList.push({
                count: product.number,
                reformProductId: 0,
                reformProductDetail: product,
                reformProjectDetail: null,
                reformProjectId: product.id,
                remark: '',
                total: (product.number * product.price).toFixed(2),
                type: 'REFORM_PRODUCT'
              })
            }
          })
        })
      })
      this.dialogProductVisible = false
      this.handleRequireTotal()
    },

    /**
     * 添加增项费用
     */
    addRequireItemList() {
      this.addList.push({
        count: 1,
        reformProductId: 0,
        reformProjectId: 0,
        remark: '',
        total: 0,
        type: 'REFORM_ADDITIONAL'
      })
    },

    /**
     * 删除增项
     * @param {Object} index
     */
    deleteRequireItemList(index) {
      this.addList.splice(index, 1)
    },

    /**
     * 删除项目
     */
    deleteRequireType(index) {
      this.requireForm.typeList.splice(index, 1)
    },

    /**
     * 计算总和
     */
    handleRequireTotal() {
      let total = 0
      this.requireForm.typeList.forEach(item => {
        item.total = (parseFloat(item.count) * parseFloat(item.reformProjectDetail.price)).toFixed(2)
        total += parseFloat(item.total)
      })
      this.requireForm.productList.forEach(item => {
        item.total = (parseFloat(item.count) * parseFloat(item.reformProductDetail.price)).toFixed(2)
        total += parseFloat(item.total)
      })
      this.requireForm.addList.forEach(item => {
        total += parseFloat(item.total)
      })
      this.requireForm.total = total.toFixed(2)
    },

    goDetail(row) {
      this.elderlyDataType = row.elderlyDataType
      if (row.elderlyDataType === 'APPLY') {
        this.applyDetail({ id: row.id }).then(res => {
          if (res.code === 200) {
            this.tableDetail = res.data
            this.showDetail = true
          }
        })
      } else if (row.elderlyDataType === 'BASIC_INFO') {
        this.basicDetail({ id: row.id }).then(res => {
          if (res.code === 200) {
            this.tableDetail = res.data
            this.showDetail = true
          }
        })
      } else if (row.elderlyDataType === 'REQUIRE_CONFIRM') {
        this.requireDetail({ id: row.id }).then(res => {
          if (res.code === 200) {
            const typeList = []
            const productList = []
            const addList = []
            res.data.requireItemDetailList.forEach(item => {
              if (item.type === 'REFORM_PROJECT') {
                typeList.push(item)
              } else if (item.type === 'REFORM_PRODUCT') {
                productList.push(item)
              } else {
                addList.push(item.remark + ' ' + item.total)
              }
            })
            res.data.typeList = typeList
            res.data.productList = productList
            res.data.addList = addList.join(';')
            this.tableDetail = res.data
            this.showDetail = true
          }
        })
      } else if (row.elderlyDataType === 'REQUIRE_CONSTRUCT') {
        this.constructDetail({ id: row.id }).then(res => {
          if (res.code === 200) {
            const product = []
            const project = []
            const requireItemConstructDetails = {}
            res.data.requireItemConstructDetails.forEach((item) => {
              requireItemConstructDetails[item.requireItemId] = item
            })
            res.data.requireItemDetailList.forEach((item, index) => {
              if (item.type === 'REFORM_PRODUCT') {
                if (requireItemConstructDetails.hasOwnProperty(item.requireItemId)) {
                  item.imgs = requireItemConstructDetails[item.requireItemId]
                }
                for (let i = 0; i < item.count; i++) {
                  let productOne = JSON.parse(JSON.stringify(item))
                  productOne.count = 1;
                  productOne.imgs.afterConstructImgList = productOne.imgs.afterConstructImgList.hasOwnProperty(i) ? [productOne.imgs.afterConstructImgList[i]] : []
                  productOne.imgs.beforeConstructImgList = productOne.imgs.beforeConstructImgList.hasOwnProperty(i) ? [productOne.imgs.beforeConstructImgList[i]] : []
                  productOne.imgs.inConstructImgList = productOne.imgs.inConstructImgList.hasOwnProperty(i) ? [productOne.imgs.inConstructImgList[i]] : []
                  product.push(productOne)
                }
              } else if (item.type === 'REFORM_PROJECT') {
                if (requireItemConstructDetails.hasOwnProperty(item.requireItemId)) {
                  item.imgs = requireItemConstructDetails[item.requireItemId]
                }
                for (let i = 0; i < item.count; i++) {
                  let projectOne = JSON.parse(JSON.stringify(item))
                  projectOne.count = 1;
                  projectOne.imgs.afterConstructImgList = projectOne.imgs.afterConstructImgList.hasOwnProperty(i) ? [projectOne.imgs.afterConstructImgList[i]] : []
                  projectOne.imgs.beforeConstructImgList = projectOne.imgs.beforeConstructImgList.hasOwnProperty(i) ? [projectOne.imgs.beforeConstructImgList[i]] : []
                  projectOne.imgs.inConstructImgList = projectOne.imgs.inConstructImgList.hasOwnProperty(i) ? [projectOne.imgs.inConstructImgList[i]] : []
                  project.push(projectOne)
                }
              }
            })
            res.data.product = product
            res.data.project = project
            this.tableDetail = res.data
            this.showDetail = true
          }
        })
      } else if (row.elderlyDataType === 'REQUIRE_ACCEPT') {
        this.acceptDetail({ id: row.id }).then(res => {
          if (res.code === 200) {
            const typeList = []
            const productList = []
            const addList = []
            let total = 0
            res.data.requireItemDetailList.forEach(item => {
              total += parseFloat(item.total)
              for (let i = 0; i < item.count; i++) {
                const itemData = JSON.parse(JSON.stringify(item))
                itemData.count = 1
                res.data.requireItemAcceptDetails.forEach(detailItem => {
                  if (detailItem.requireItemId === itemData.requireItemId) {
                    itemData.state = (detailItem.stateAndRemarkList != null && detailItem.stateAndRemarkList.hasOwnProperty(i)) ? detailItem.stateAndRemarkList[i].state : detailItem.state
                    itemData.acceptRemark = (detailItem.stateAndRemarkList != null && detailItem.stateAndRemarkList.hasOwnProperty(i)) ? detailItem.stateAndRemarkList[i].remark : detailItem.remark
                  }
                })
                if (itemData.type === 'REFORM_PROJECT') {
                  typeList.push(itemData)
                } else if (itemData.type === 'REFORM_PRODUCT') {
                  productList.push(itemData)
                } else {
                  addList.push(itemData.remark + ' ' + itemData.total)
                }
              }
            })
            const beforeConstructImgList = []
            const inConstructImgList = []
            const afterConstructImgList = []
            res.data.requireItemConstructDetails.forEach(item => {
              item.beforeConstructImgList.forEach(img => {
                beforeConstructImgList.push(img)
              })
              item.inConstructImgList.forEach(img => {
                inConstructImgList.push(img)
              })
              item.afterConstructImgList.forEach(img => {
                afterConstructImgList.push(img)
              })
            })
            res.data.typeList = typeList
            res.data.total = total.toFixed(2)
            res.data.productList = productList
            res.data.addList = addList.join(';')
            res.data.beforeConstructImgList = beforeConstructImgList
            res.data.inConstructImgList = inConstructImgList
            res.data.afterConstructImgList = afterConstructImgList
            this.tableDetail = res.data
            this.showDetail = true
          }
        })
      } else if (row.elderlyDataType === 'REFORM_GIVE_UP') {
        this.giveUpDetail({ id: row.id }).then(res => {
          this.tableDetail = res.data
          this.showDetail = true
        })
      } else if (row.elderlyDataType === 'PERSON_ASSESS') {
        this.personDetail({ id: row.id }).then(res => {
          const accessDetail = JSON.parse(res.data.accessDetail)
          accessDetail.forEach(item => {
            item.name = item.name.replace('【多选】', '')
            if (item.type === 1) {
              if (item.options[item.value - 1].type === 1) {
                item.showValue = item.options[item.value - 1].value
              } else {
                item.showValue = '有' + item.options[item.value - 1].extendValue + '位'
              }
            } else if (item.type === 2) {
              const showValue = []
              item.value.forEach(valueItem => {
                if (item.options[valueItem - 1].type === 1) {
                  showValue.push(item.options[valueItem - 1].value)
                } else {
                  showValue.push(item.options[valueItem - 1].extendValue)
                }
              })
              item.showValue = showValue.join(',')
            } else if (item.type === 3) {
              item.options.forEach(optionItem => {
                optionItem.showValue = optionItem.options[optionItem.chooseValue - 1].name
              })
            }
          })
          const typeList = []
          const productList = []
          if (res.data.reformRequireDetail != null && res.data.reformRequireDetail.requireItemDetailList != null) {
            res.data.reformRequireDetail.requireItemDetailList.forEach(item => {
              if (item.type === 'REFORM_PROJECT') {
                typeList.push(item)
              } else if (item.type === 'REFORM_PRODUCT') {
                productList.push(item)
              }
            })
          }
          console.log(accessDetail)
          res.data.accessDetail = accessDetail
          res.data.typeList = typeList
          res.data.productList = productList
          this.tableDetail = res.data
          this.showDetail = true
        })
      }
    },
    formShow() {
      this.elderlyDataType = this.tabList[this.tabIndex].elderlyDataType
      if (this.elderlyDataType === 'APPLY') {
        this.applyForm = {
          id: '',
          censusAddress: '',
          age: '',
          contract: '',
          emergencyContactName: '',
          emergencyContactRelateShip: '',
          emergencyContactTel: '',
          fromPc: true,
          houseType: '',
          idNum: '',
          imgs: '',
          imgInfoList: [],
          materialFiles: '',
          materialFileInfoList: [],
          name: '',
          oldPersonId: '',
          personTypeArr: [],
          selfCareAbiy: '',
          sex: '',
          tenant: [],
          usualAddress: '',
          houseBelongOwn: ''
        }
        this.showForm = true
      } else if (this.elderlyDataType === 'REFORM_GIVE_UP') {
        this.giveUpForm = {
          id: '',
          name: '',
          sex: '',
          age: '',
          idNum: '',
          contract: '',
          personTypeArr: [],
          selfCareAbiy: '',
          tenant: [],
          censusAddress: '',
          workerCompId: '',
          oldPersonPapers: '',
          oldPersonPaperList: [],
          caPapers: '',
          caPaperList: []
        }
        this.showForm = true
      } else if (this.elderlyDataType === 'REQUIRE_CONFIRM') {
        this.requireForm = {
          id: '',
          name: '',
          sex: '',
          age: '',
          idNum: '',
          contract: '',
          personTypeArr: [],
          selfCareAbiy: '',
          tenant: [],
          censusAddress: '',
          productList: [],
          typeList: [],
          addList: [],
          addListString: '',
          confirmPapers: '',
          confirmPaperList: [],
          total: 0
        }
        this.showForm = true
      }
    },
    updateList(row) {
      this.elderlyDataType = row.elderlyDataType
      console.log(this.elderlyDataType)
      this.showForm = true
      if (row.elderlyDataType === 'APPLY') {
        this.applyDetail({ id: row.id }).then(res => {
          if (res.code === 200) {
            for (const i in this.applyForm) {
              this.applyForm[i] = res.data[i]
            }
          }
        })
      } else if (row.elderlyDataType === 'REFORM_GIVE_UP') {
        this.giveUpDetail({ id: row.id }).then(res => {
          if (res.code === 200) {
            for (const i in this.giveUpForm) {
              this.giveUpForm[i] = res.data[i]
            }
          }
        })
      } else if (row.elderlyDataType === 'REQUIRE_CONFIRM') {
        this.requireDetail({ id: row.id }).then(res => {
          if (res.code === 200) {
            const typeList = []
            const productList = []
            const addList = []
            const addListString = []
            res.data.requireItemDetailList.forEach(item => {
              if (item.type === 'REFORM_PROJECT') {
                typeList.push(item)
              } else if (item.type === 'REFORM_PRODUCT') {
                productList.push(item)
              } else {
                addList.push({
                  count: item.count,
                  reformProductId: 0,
                  reformProjectId: 0,
                  remark: item.remark,
                  total: item.total,
                  type: 'REFORM_ADDITIONAL'
                })
                addListString.push(item.remark + ' ' + item.total)
              }
            })
            res.data.typeList = typeList
            res.data.productList = productList
            res.data.addList = addList
            res.data.addListString = addListString.join(';')
            for (const i in this.requireForm) {
              this.requireForm[i] = res.data[i]
            }
            this.showForm = true
          }
        })
      }
    },

    deleteList(row) {
      this.$XModal.confirm('您确定要删除该数据?').then(type => {
        if (type === 'confirm') {
          console.log(row.elderlyDataType)
          if (row.elderlyDataType === 'APPLY') {
            this.applyDelete(row.id).then(res => {
              if (res.code === 200) {
                this.$message.success('删除成功')
                this.tablePage.currentPage = 1
                this.queryList()
              }
            })
          } else if (row.elderlyDataType === 'BASIC_INFO') {
            this.basicDelete(row.id).then(res => {
              if (res.code === 200) {
                this.$message.success('删除成功')
                this.tablePage.currentPage = 1
                this.queryList()
              }
            })
          } else if (row.elderlyDataType === 'REQUIRE_CONFIRM') {
            this.requireDelete(row.id).then(res => {
              if (res.code === 200) {
                this.$message.success('删除成功')
                this.showForm = false
                this.tablePage.currentPage = 1
                this.queryList()
              }
            })
          } else if (row.elderlyDataType === 'REQUIRE_CONSTRUCT') {
            this.constructDelete(row.id).then(res => {
              if (res.code === 200) {
                this.$message.success('删除成功')
                this.showForm = false
                this.tablePage.currentPage = 1
                this.queryList()
              }
            })
          } else if (row.elderlyDataType === 'REQUIRE_ACCEPT') {
            this.acceptDelete(row.id).then(res => {
              if (res.code === 200) {
                this.$message.success('删除成功')
                this.showForm = false
                this.tablePage.currentPage = 1
                this.queryList()
              }
            })
          } else if (row.elderlyDataType === 'REFORM_GIVE_UP') {
            this.giveUpDelete(row.id).then(res => {
              if (res.code === 200) {
                this.$message.success('删除成功')
                this.tablePage.currentPage = 1
                this.queryList()
              }
            })
          } else if (row.elderlyDataType === 'PERSON_ASSESS') {
            this.personDelete(row.id).then(res => {
              if (res.code === 200) {
                this.$message.success('删除成功')
                this.tablePage.currentPage = 1
                this.queryList()
              }
            })
          } else if (row.elderlyDataType === 'REFORM_SUMMARY') {
            this.elderlyOrientedSummaryDelete(row.id).then(res => {
              if (res.code === 200) {
                this.$message.success('删除成功')
                this.tablePage.currentPage = 1
                this.queryList()
              }
            })
          }
        }
      })
    },
    showMore(row) {
      row.isMore = !row.isMore
    },

    showLess(row) {
      row.isMore = !row.isMore
    },

    submitApplyForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.applyForm.oldPersonId = this.$route.params.id
          if (this.applyForm.id === '') {
            this.applyAdd(this.applyForm).then(res => {
              if (res.code === 200) {
                this.$message.success('添加成功')
                this.showForm = false
                this.tablePage.currentPage = 1
                this.queryList()
              }
            })
          } else {
            this.applyUpdate(this.applyForm).then(res => {
              if (res.code === 200) {
                this.$message.success('修改成功')
                this.showForm = false
                this.tablePage.currentPage = 1
                this.queryList()
              }
            })
          }
        } else {
          return false
        }
      })
    },

    submitGiveUp(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.giveUpForm.oldPersonId = this.$route.params.id
          if (this.giveUpForm.id === '') {
            this.giveUpForm.workerId = this.userId
            this.giveUpAdd(this.giveUpForm).then(res => {
              if (res.code === 200) {
                this.$message.success('添加成功')
                this.showForm = false
                this.tablePage.currentPage = 1
                this.queryList()
              }
            })
          } else {
            this.giveUpUpdate(this.giveUpForm).then(res => {
              if (res.code === 200) {
                this.$message.success('修改成功')
                this.showForm = false
                this.tablePage.currentPage = 1
                this.queryList()
              }
            })
          }
        } else {
          return false
        }
      })
    },

    submitRequire(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const requireItemList = []
          this.requireForm.productList.forEach(item => {
            item.remark = 'system'
            requireItemList.push(item)
          })
          this.requireForm.typeList.forEach(item => {
            item.remark = 'system'
            requireItemList.push(item)
          })
          this.requireForm.addList.forEach(item => {
            requireItemList.push(item)
          })
          const postData = {
            confirmPapers: this.requireForm.confirmPapers,
            oldPersonId: this.$route.params.id,
            remark: 'system',
            requireItemList: requireItemList,
            total: this.requireForm.total,
            workerId: this.userId
          }
          if (this.requireForm.id === '') {
            this.requireAdd(postData).then(res => {
              if (res.code === 200) {
                this.$message.success('添加成功')
                this.showForm = false
                this.tablePage.currentPage = 1
                this.queryList()
              }
            })
          } else {
            postData.id = this.requireForm.id
            this.requireUpdate(postData).then(res => {
              if (res.code === 200) {
                this.$message.success('修改成功')
                this.showForm = false
                this.tablePage.currentPage = 1
                this.queryList()
              }
            })
          }
        } else {
          return false
        }
      })
    },

    addProject(type) {
      if (type == 'edit') {
        this.isedit = true
        const params = {
          oldPersonId: this.$route.params.id
        }
        this.elderReformDetai(params).then(res => {
          console.log(res)
          if (res.code == 200) {
            this.isshow = true
            this.form = {
              ...res.data
            }
            console.log(this.form.beforeImgs)
            if (this.form.beforeImgs) {
              console.log(123213)
              this.getFileDetail(this.form.beforeImgs, 1)
            }
            if (this.form.afterImgs) {
              this.getFileDetail(this.form.afterImgs, 2)
            }
            if (this.form.otherImgs) {
              this.getFileDetail(this.form.otherImgs, 3)
            }
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        this.isedit = false
      }
      this.dialogFormVisible = true
    },
    // 查看图片详情
    getFileDetail(ids, type) {
      this.beforeImgsList = []
      this.afterImgsList = []
      this.otherImgsList = []
      ids = ids.split(',')
      console.log(ids, type)
      ids.forEach(item => {
        this.downloadFile(item).then(res => {
          if (type == 1) {
            this.beforeImgsList.push({
              id: item,
              url: window.URL.createObjectURL(res.data)
            })
          } else if (type == 2) {
            this.afterImgsList.push({
              id: item,
              url: window.URL.createObjectURL(res.data)
            })
          } else {
            this.otherImgsList.push({
              id: item,
              url: window.URL.createObjectURL(res.data)
            })
          }
        })
      })
    },

    onBeforeImg(data) {
      this.form.beforeImgs = data.join(',')
    },
    onAfterImg(data) {
      this.form.afterImgs = data.join(',')
    },
    onOtherImg(data) {
      this.form.otherImgs = data.join(',')
    },

    applyFormImg(data) {
      this.applyForm.imgs = data.join(',')
    },

    applyFormMaterialFiles(data) {
      this.applyForm.materialFiles = data.join(',')
    },

    giveUpFormOldPersonPaper(data) {
      this.giveUpForm.oldPersonPapers = data.join(',')
    },

    giveUpFormCaPapers(data) {
      this.giveUpForm.caPapers = data.join(',')
    },

    requireFormConfirmPapers(data) {
      this.requireForm.confirmPapers = data.join(',')
    },

    requireFormOldPersonPaper(data) {
      console.log(data)
    },

    queryDetail() {
      const params = {
        oldPersonId: this.$route.params.id
      }
      this.elderReformDetai(params).then(res => {
        console.log(res)
        if (res.code == 200) {
          if (res.data == null) {
            this.isshow = false
          } else {
            this.isshow = true
            this.table = {
              ...res.data
            }
            this.id = res.data.id
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    querySumCenterList() {
      this.getServeOrgsListServe({
        current: 1,
        size: 9999,
        param: {}
      }).then(res => {
        // console.log(res,'querySumCenterList');
        if (res.code == 200) {
          this.orgDatas = res.data.records
          console.log(this.orgDatas)
        }
        this.loading = false
      })
    },
    // 添加
    addElderBtn() {
      if (this.isedit) {
        const params = this.form
        this.updateElderReform(params).then(res => {
          if (res.code == 200) {
            this.$message.success('修改成功')
            this.form = {}
            this.dialogFormVisible = false
            this.queryDetail()
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        const params = this.form
        this.addElderReform(params).then(res => {
          if (res.code == 200) {
            console.log(res, '添加')
            this.$message.success('添加成功')
            this.form = {}
            this.dialogFormVisible = false
            this.queryDetail()
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    // 删除
    delProject() {
      this.$XModal.confirm('您确定要删除该数据?').then(type => {
        if (type === 'confirm') {
          const params = this.id
          this.deleteElderReform(params).then(res => {
            console.log(res)
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '删除成功'
              })
              this.queryDetail()
            }
          })
        } else {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.servertable {
  position: relative;
}

.backwhite {
  background-color: #fff;
  width: 98%;
  padding: 40px 0 0 50px;
  float: left;
}

.table {
  background-color: transparent;
  max-width: 100%;
  margin-bottom: 20px;
  width: 98%;
  border-spacing: 0;
  border-collapse: collapse;
}

/* :deep .el-descriptions__header{
  display: flex;
  justify-content: center !important;

  font-size: 24px;
} */
/* :deep .el-descriptions__header .el-descriptions__title{
    color: #488fe1;
    font-size: 24px;
    padding: 0;
    font-weight: 400;
}
.img{
  width: 150px;
  height: 150px;
} */
.table tr .td1111 {
  width: 170px;
  padding-left: 24px;
}

.td1111 {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #333;
  border-spacing: 0;
  border-collapse: collapse;
  margin: 0;
  box-sizing: border-box;
  font-family: "Microsoft YaHei", "微软雅黑";
  list-style: none;
  outline: none;
  text-decoration: none;
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: middle;
  font-size: 14px;
  height: 50px;
  border: 1px solid #e2e5e7;
  width: 170px;
  padding-left: 24px;

}

.float-left {
  float: left;
}

.tableinfo {
  margin-left: 1em;
}

.frow {
  width: 100%;
  display: flex;
  align-items: center;
  float: left;
  display: -moz-box;
  /* display: -webkit-box; */
  display: box;
  -moz-box-align: center;
  -webkit-box-align: center;
  display: -ms-flexbox;
  -ms-flex-align: center;
}

.tableinfo {
  margin-left: 1em;
}

.frow .fcol {
  float: left;
  /* height: 60px; */
  line-height: 60px;
  position: relative;
}

.w200 {
  width: 200px;
}

.w400 {
  width: 300px;
}

.font14 {
  font-size: 14px;
}

.table tr td {
  vertical-align: middle;
  font-size: 14px;
  height: 50px;
  border: 1px solid #e2e5e7;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: middle;
  /* border-top: 1px solid #ddd; */
}

.table .caption {
  color: #333333;
  font-size: 24px;
  padding: 0;
  margin-bottom: 16px;
}

.table tr td {
  vertical-align: middle;
  font-size: 14px;
  height: 50px;
  border: 1px solid #e2e5e7;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

table tr td .bz {
  color: #777676;
  line-height: 31px;
  padding-left: 17px;
  padding-right: 50px;
}

.table tr td .bz h2 {
  font-size: 18px;
  margin: 5px 0px;
  line-height: 1em;
  font-family: inherit;
  font-weight: 500;
  color: inherit;
}

.table tr td .bz p {
  padding-left: 11px;
}

.table .btngroup {
  margin: 15px 0;
}

.table .btngroup .blue {
  background-color: #488fe1;
}

.table .btngroup a {
  color: white;
  font-size: 16px;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 6px;
  margin: 0 16px;
}

.text-center {
  text-align: center;
}

.table a, .servertable .table a:visited, .servertable .table a:active, .servertable .table a:link, .servertable .table a:hover {
  color: #5093E2;
  font-size: 14px;
}

.userimg {
  display: contents;
}

.userimg img {
  border: 1px solid #e7e7e7;
  margin-bottom: 10px;
  margin-left: 5px;
}

.servertable .table .btngroup .blue:hover {
  background-color: #3183E3;
}

.servertable .table .btngroup a:hover {
  color: white;
  font-size: 16px;
}

.servertable .table tr td .bz header {
  vertical-align: middle;
  font-size: 14px;
  height: auto;
  border: none;
  padding: 0;
}

.tab-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.tab {
  padding: 8px 32px;
  background: #ECF4FC;
  border-radius: 17px;
  font-size: 14px;
  color: #2D8CF0;
  margin-right: 20px;
  margin-top: 10px;
}

.tab-choose {
  background-color: #2D8CF0 !important;
  color: #FFFFFF !important;
}

.productPicture {
  width: 80px;
  height: 80px;
}

.add-image {
  margin-top: 10px;
  width: 32px;
  height: 32px;
}

.handle-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.handle-image {
  margin-left: 20px;
  width: 14px;
  height: 14px;
}

.margin-left-20 {
  margin-left: 20px;
}

.title {
  margin-bottom: 30px;
}

.required-list {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #CCCCCC;
}

.required-list td {
  height: 80px;
}

.project-title-list {
  display: flex;
  flex-direction: column;
}

.project-title {
  width: 320px;
  height: 20px;
  padding: 10px 20px;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  margin-bottom: 12px;
}

.project-choose {
  background: #2D8CF0 !important;
  color: #FFFFFF !important;
}

.project-content-title {
  display: flex;
  align-items: center;
  width: 320px;
  height: 20px;
  padding: 10px 20px;
  background: #FFFFFF;
  border-radius: 2px 2px 0 0;
  border: 1px solid #D8D8D8;
}

.require-return {
  width: 8px;
  height: 12px;
  margin-right: 16px;
}

.product-content-content {
  width: 320px;
  display: flex;
  align-items: start;
  padding: 12px 20px;
  border: 1px solid #D8D8D8;
}

.product-content-content:not(first-child) {
  border-top: none;
}

.product-content-content-desc {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  flex: 1;
}

.product-desc-content {
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.product-desc-content > .desc {
  flex: 1;
  color: #999999;
}

.require-check {
  margin-top: 3px;
  width: 16px;
  height: 16px;
}

.number-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 15px;
}

.number-handle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.number {
  width: 21px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
}

.number:first-child {
  border-radius: 2px 0px 0px 2px;
}

.number:nth-child(3) {
  border-radius: 0 2px 2px 0;
}

.add-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.add_list {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.add_money {
  display: flex;
  align-items: center;
  width: 96px;
  padding: 10px;
  border-radius: 2px;
  border: 1px solid #D8D8D8;
  font-weight: bolder;
}

/deep/ .add_money .el-input__inner {
  border: none;
  height: 20px;
}

.add_money > input {
  flex: 1;
  margin-left: 8px;
}

.add_text {
  margin-left: 16px;
  font-size: 12px;
  color: #666666;
}

.add_remark {
  margin-left: 7px;
  padding: 10px 0;
  border-radius: 2px;
  border: 1px solid #D8D8D8;
  flex: 1;
}

/deep/ .add_remark .el-input__inner {
  border: none;
  height: 20px;
}

.delete_item {
  margin-left: 20px;
  width: 14px;
  height: 14px;
}

.product-left {
  width: 88px;
  height: 332px;
  overflow-y: auto;
  background: #FFFFFF;
  border-radius: 0px 0px 0px 2px;
  border: 1px solid #D8D8D8;
}

.product-right {
  width: 272px;
  height: 332px;
  background: #FFFFFF;
  border-radius: 0px 0px 2px 2px;
  border: 1px solid #D8D8D8;
  overflow-y: auto;
}

.product-left > div {
  width: 88px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.product-left-choose {
  background: #2D8CF0 !important;
  color: #FFFFFF;
}

.product-product-item {
  display: flex;
  flex-direction: column;
  padding: 19px 20px 20px 20px;
  border-bottom: 1px solid #F6F6F6;
}

.flex-align-start {
  display: flex;
  align-items: flex-start;
}

.prouct-item-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  color: #666666;
  margin-left: 11px;
}

.top-10 {
  margin-top: 6px;
}

.top-12 {
  margin-top: 7px;
}

.color-66 {
  color: #666666;
}

.product-number {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 14px 0;
  margin-top: 11px;
  border-top: 1px solid #f6f6f6;
}

.position-relation {
  position: relative;
}

.export-button {
  position: absolute;
  top: 8px;
  right: 17px;
  width: 72px;
  height: 34px;
  line-height: 34px;
  background: #2D8CF0;
  border-radius: 4px;
  font-size: 16px;
  color: #FFFFFF;
}
</style>

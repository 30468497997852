<template>
  <div>
    <div>
      <el-button type="primary" size="medium" icon="el-icon-plus" @click="addProject()">添加</el-button>

    </div>
    <div v-if="!isshow" style="text-align: center">暂无体检报告</div>
    <div v-if="isshow" class="backwhite servertable">
      <table v-for="(item, index) in table" class="table">
        <tbody>
          <tr>
            <td class="td1111">
              <div class="userimg float-left">
                <img
                  width="107"
                  height="135"
                  src="https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg"
                  onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'"
                >
              </div>
            </td>
            <td colspan="4">
              <div class="tableinfo">
                <div class="frow">
                  <div class="fcol w200">
                    <label> <span class="font14">姓</span> <span class="font14">名：</span>
                    </label> <label><span class="font14"><a>{{ item.oldPersonName }}</a></span></label>
                  </div>
                  <div class="fcol w400">
                    <label>体检编号：</label> <label><span class="font14"><a>{{ item.examNo }}</a></span></label>
                  </div>
                  <div class="fcol">
                    <label>体检时间：</label> <label><span class="font14">{{ item.examTime }}</span></label>
                  </div>

                </div>
                <div class="frow">
                  <div class="fcol w200">
                    <label>联系电话：</label> <label><span class="font14">{{ item.oldPersonPhone }}</span></label>
                  </div>
                  <div class="fcol">
                    <label><span class="font14">性</span><span class="font14">别：</span></label> <label><span
                      class="font14"
                    >{{ item.oldPersonGender }}</span></label>
                  </div>
                </div>
                <div class="frow">
                  <div class="fcol">
                    <label>出生日期：</label> <label><span class="font14">{{ item.oldPersonBirthTime }}</span></label>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="text-center"><span class="font14">服务机构</span></td>
            <td class="text-left" colspan="5"><span class="font14 tableinfo">{{ item.orgName }}</span></td>
          </tr>
          <tr>
            <td class="text-center"><span class="font14">体检报告</span></td>
            <td class="text-left" colspan="5">
              <div class="userimg float-left">
                <el-image
                  v-for="(picture, pictureIndex) in item.examPicList"
                  :key="pictureIndex"
                  style="width: 100px; height: 100px"
                  :src="picture.url"
                  :preview-src-list="[picture.url]"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <el-dialog
      title="添加体检报告"
      width="530px"
      :visible.sync="dialogFormVisible"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-form :model="form" label-position="left">
        <el-form-item label="服务机构" :label-width="formLabelWidth">
          <el-select v-model="form.orgId" placeholder="请选择">
            <el-option v-for="item in orgDatas" :key="item.id" :label="item.compName" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="上传体检报告" :label-width="formLabelWidth">
          <upload-img :limit="6" :upload-lists="beforeImgsList" @uploadChange="onBeforeImg" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="() => { dialogFormVisible = false, form = {} }">取 消</el-button>
        <el-button type="primary" @click="addElderBtn()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import UploadImg from '@/components/UploadImg.vue'

export default {
  components: {
    UploadImg
  },

  data() {
    return {
      size: 'small ',
      // oldPersonName:'aaa', //老人姓名
      table: [],
      isshow: '',
      dialogFormVisible: false,
      form: {
        oldPersonId: this.$route.params.id,
        orgId: ''
      },
      formLabelWidth: '120px',
      beforeImgsList: [],
      orgDatas: [],
      isedit: false,
      isplus: false,
      id: ''
    }
  },
  created() {
    this.queryDetail()
    this.querySumCenterList()
  },
  methods: {
    ...mapActions([
      'downloadFile',
      'examReportAdd',
      'getServeOrgsListServe',
      'getExamReport'
    ]),

    addProject() {
      this.dialogFormVisible = true
    },
    // 查看图片详情
    getFileDetail(ids, type) {
      this.beforeImgsList = []
      if (type != 1) {
        return
      }
      ids = ids.split(',')
      ids.forEach(item => {
        this.downloadFile(item).then(res => {
          this.beforeImgsList.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },

    onBeforeImg(data) {
      this.form.beforeImgs = data.join(',')
    },
    queryDetail() {
      const params = {
        id: this.$route.params.id
      }
      this.getExamReport(params).then(res => {
        if (res.code == 200) {
          if (res.data == null || res.data.length <= 0) {
            this.isshow = false
          } else {
            this.isshow = true
            res.data.forEach(item => {
              item.examPicList.forEach(picture => {
                picture.url = picture.url.substr(0, picture.url.lastIndexOf('?'))
              })
            })
            this.table = {
              ...res.data
            }
            this.id = res.data.id
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },

    /**
     * 查询机构列表
     */
    querySumCenterList() {
      this.getServeOrgsListServe({
        current: 1,
        size: 9999,
        param: {}
      }).then(res => {
        if (res.code == 200) {
          this.orgDatas = res.data.records
          console.log(this.orgDatas)
        }
        this.loading = false
      })
    },
    // 添加
    addElderBtn() {
      const params = {
        oldPersonId: this.$route.params.id,
        orgId: this.form.orgId,
        examPicIds: this.form.beforeImgs
      }
      this.examReportAdd(params).then(res => {
        if (res.code == 200) {
          this.$message.success('添加成功')
          this.form = {}
          this.dialogFormVisible = false
          this.queryDetail()
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
.servertable {
  position: relative;
}

.backwhite {
  background-color: #fff;
  width: 98%;
  float: left;
}

.table {
  background-color: transparent;
  max-width: 100%;
  margin-bottom: 20px;
  width: 98%;
  border-spacing: 0;
  border-collapse: inherit;
}

table tr:first-child td:first-child {
  border-top-left-radius: 10px;
  /* 设置table左下圆角 */
}

table tr:first-child td:last-child {
  border-top-right-radius: 10px;
  /* 设置table右下圆角 */
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
  /* 设置table左下圆角 */
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
  /* 设置table右下圆角 */
}

table tr:first-child td {
  border-bottom: none;
}

table tr:last-child td {
  border-top: none;
}

/* :deep .el-descriptions__header{
  display: flex;
  justify-content: center !important;

  font-size: 24px;
} */
/* :deep .el-descriptions__header .el-descriptions__title{
    color: #488fe1;
    font-size: 24px;
    padding: 0;
    font-weight: 400;
}
.img{
  width: 150px;
  height: 150px;
} */
.table tr .td1111 {
  width: 170px;
  padding-left: 24px;
}

.td1111 {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #333;
  border-spacing: 0;
  border-collapse: collapse;
  margin: 0;
  box-sizing: border-box;
  font-family: "Microsoft YaHei", "微软雅黑";
  list-style: none;
  outline: none;
  text-decoration: none;
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: middle;
  font-size: 14px;
  height: 50px;
  border: 1px solid #e2e5e7;
  width: 170px;
  padding-left: 24px;

}

.float-left {
  float: left;
}

.tableinfo {
  margin-left: 1em;
}

.frow {
  width: 100%;
  display: flex;
  align-items: center;
  float: left;
  display: -moz-box;
  /* display: -webkit-box; */
  display: box;
  -moz-box-align: center;
  -webkit-box-align: center;
  display: -ms-flexbox;
  -ms-flex-align: center;
}

.tableinfo {
  margin-left: 1em;
}

.frow .fcol {
  float: left;
  /* height: 60px; */
  line-height: 60px;
  position: relative;
}

.w200 {
  width: 200px;
}

.w400 {
  width: 400px;
}

.font14 {
  font-size: 14px;
}

.table tr td {
  vertical-align: middle;
  font-size: 14px;
  height: 50px;
  border: 1px solid #e2e5e7;
}

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: middle;
  /* border-top: 1px solid #ddd; */
}

.table caption {
  color: #488fe1;
  font-size: 24px;
  padding: 0;
  margin-bottom: 16px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

table tr td .bz {
  color: #777676;
  line-height: 31px;
  padding-left: 17px;
  padding-right: 50px;
}

.table tr td .bz h2 {
  font-size: 18px;
  margin: 5px 0px;
  line-height: 1em;
  font-family: inherit;
  font-weight: 500;
  color: inherit;
}

.table tr td .bz p {
  padding-left: 11px;
}

.table .btngroup {
  margin: 15px 0;
}

.table .btngroup .blue {
  background-color: #488fe1;
}

.table .btngroup a {
  color: white;
  font-size: 16px;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 6px;
  margin: 0 16px;
}

.text-center {
  text-align: center;
}

.table a,
.servertable .table a:visited,
.servertable .table a:active,
.servertable .table a:link,
.servertable .table a:hover {
  color: #5093E2;
  font-size: 14px;
}

.userimg img {
  border: 1px solid #e7e7e7;
  margin-bottom: 10px;
  margin-left: 5px;
}

.servertable .table .btngroup .blue:hover {
  background-color: #3183E3;
}

.servertable .table .btngroup a:hover {
  color: white;
  font-size: 16px;
}

.servertable .table tr td .bz header {
  vertical-align: middle;
  font-size: 14px;
  height: auto;
  border: none;
  padding: 0;
}

.uploadImages {
  display: flex;
  flex-wrap: wrap;
}

/deep/ .demo-upload-list {
  margin-right: 20px;
}
</style>
